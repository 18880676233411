import { isValidElement, useCallback, useState } from 'react';

import { LogEvent } from '@analytics';
import { useGetUser } from '@db/collections';
import { addDays } from 'date-fns';
import { AppBarItem, NewIcon, useToast } from 'design-system';
import { atom } from 'jotai';
import { useRouter } from 'next/router';
import { useLocalStorage } from 'usehooks-ts';

import { overrideCouponExpirationDate } from '@components/Activation/BlogExploreCouponEvent';
import {
  CARDOC_COUPON_CODE,
  COUPON_EXPIRATION_DAYS,
} from '@components/Layout/GlobalModal/components/CardocCouponModal';
import { MarketingAgreementBottomSheet } from '@components/MarketingAgreementBottomSheet';
import { NeedLoginBottomSheet } from '@components/NeedLoginBottomSheet/NeedLoginBottomSheet';
import { Popover } from '@components/Popover';

import { useMounted } from '@hooks';
import { isAppApproaching } from '@utils/Common';

import { BackLink } from './BackLink';

const isAppApproach = isAppApproaching();

export const visitCouponModalAtom = atom(false);

const RemainingCoupon = ({ children }: { children: React.ReactElement }) => {
  const [lastUnreceivedCouponCode, setLastUnreceivedCouponCode] = useLocalStorage(
    'last-unreceived-coupon-code',
    '',
  );
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const mounted = useMounted();
  const router = useRouter();
  const { user } = useGetUser();

  const { openToast } = useToast();

  if (!mounted) return null;
  return (
    <div ref={setContainer} className="z-[1000]">
      <Popover
        container={container}
        align="end"
        content={'안 받으신 쿠폰이 있어요! 🎁'}
        side="bottom"
        color="purple"
        closeButton={false}
        sideOffset={10}
        alignOffset={-16}
        open={Boolean(lastUnreceivedCouponCode)}
      >
        <MarketingAgreementBottomSheet
          onSubmit={async () => {
            if (!user) return;

            try {
              if (lastUnreceivedCouponCode) {
                await user.addCoupon({
                  code: lastUnreceivedCouponCode,
                });

                if (lastUnreceivedCouponCode === CARDOC_COUPON_CODE) {
                  await overrideCouponExpirationDate({
                    userId: user.uid,
                    couponCode: CARDOC_COUPON_CODE,
                    expirationDate: addDays(new Date(), COUPON_EXPIRATION_DAYS),
                  });
                  await router.push('/profile/storage/coupon');
                }
                openToast({
                  title: '쿠폰이 발급되었습니다.',
                });
                setLastUnreceivedCouponCode('');
              } else {
                router.push('/profile/storage/coupon');
              }
            } catch (error) {
              console.error(error);
              openToast({
                title:
                  '쿠폰 받기에 실패했습니다. 지속적인 문제 발생 시 운전선생 카카오톡 채널로 문의해주세요.',
              });
            }
          }}
        >
          <NeedLoginBottomSheet>{children}</NeedLoginBottomSheet>
        </MarketingAgreementBottomSheet>
      </Popover>
    </div>
  );
};

const isApp = isAppApproaching();
export const GlobalAppBarItem = ({
  item,
  backRedirectUrl,
  forceBackLink = false,
}: {
  item?: GlobalAppBarItem;
  backRedirectUrl?: string;
  forceBackLink?: boolean;
}) => {
  const router = useRouter();

  const renderItem = useCallback(
    (item: GlobalAppBarItem) => {
      switch (item) {
        case 'Back':
          return (
            <BackLink backRedirectUrl={backRedirectUrl} forceBackLink={forceBackLink}>
              <AppBarItem
                className="p-6"
                item={<NewIcon icon="arrowline-outlined" size={24} className="fill-new-gray-900" />}
              />
            </BackLink>
          );
        case 'WhiteBack':
          return (
            <BackLink backRedirectUrl={backRedirectUrl} forceBackLink={forceBackLink}>
              <AppBarItem
                item={<NewIcon icon="arrowline-outlined" size={24} className="fill-new-white" />}
              />
            </BackLink>
          );
        case 'Close':
          return (
            <BackLink backRedirectUrl={backRedirectUrl}>
              <NewIcon icon="x-outlined" size={24} className="fill-new-gray-900" />
            </BackLink>
          );
        case 'ProfileSetting':
          return (
            <AppBarItem
              className="p-6"
              item={<NewIcon icon="gear-outlined" size={24} className="fill-new-gray-900" />}
              onClick={() => {
                router.push('/profile/setting');
              }}
            />
          );
        case 'ProfileNotification':
          if (!isAppApproach) return null;

          return (
            <AppBarItem
              className="show-on-webview p-6"
              item={<NewIcon icon="bell-outlined" size={24} className="fill-new-gray-900" />}
              onClick={() => {
                router.push('/profile/notification');
              }}
            />
          );
        case 'Coupon':
          return (
            <RemainingCoupon>
              <AppBarItem
                className="p-6"
                item={<NewIcon icon="coupon-outlined" size={24} className="fill-new-gray-900" />}
              />
            </RemainingCoupon>
          );
        case 'TextLogo':
          return (
            <AppBarItem
              item={<NewIcon icon="dt-text-logo" width={67} height={17} className="pl-8" />}
              onClick={() => {
                router.push('/');
              }}
            />
          );
        case 'TextLogoToExplore':
          return (
            <AppBarItem
              item={
                isApp ? (
                  <NewIcon icon="arrowline-outlined" size={24} className="fill-new-gray-900" />
                ) : (
                  <NewIcon icon={'text-logo'} width={107} height={32} />
                )
              }
              onClick={() => {
                LogEvent.활성화.blogToExplore('로고클릭');
                const pathname = isApp ? '/blog' : '/explore';
                router.push(pathname);
              }}
            />
          );
        case 'Home':
          return (
            <AppBarItem
              item={<NewIcon icon="home-outlined" size={24} className="fill-new-gray-900" />}
              onClick={() => {
                router.push('/');
              }}
            />
          );
        default:
          return <AppBarItem item={<div />} />;
      }
    },
    [router, backRedirectUrl],
  );

  if (!item) return <AppBarItem item={<div />} />;

  if (isValidElement(item)) return <AppBarItem item={item} />;

  return <>{renderItem(item)}</>;
};
