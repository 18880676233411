import { BaseLogEvent, BaseParams } from '../BaseLogEvent';

export class 유지 extends BaseLogEvent {
  constructor(params: BaseParams) {
    super(params);
  }

  public startTrial(category: string) {
    this.GAEvent('start_exam', {
      start_exam_category: category,
    });

    this.AirbridgeEvent.sendIndividual('airbridge.startTrial', {
      customAttributes: {
        start_exam_category: category,
      },
    });
  }

  public finishTrial(score: number) {
    this.GAEvent('complete_exam', {
      complete_exam_score: Math.floor(score / 10) * 10,
    });
    this.AirbridgeEvent.sendIndividual('airbridge.completeTutorial', {});
  }

  public startExam(type: 'mini' | 'full') {
    this.GAEvent('start_exam', {
      type,
    });

    this.AirbridgeEvent.sendIndividual(
      type === 'full' ? 'airbridge.start_exam' : 'airbridge.start_mini_exam',
    );
  }

  public finishExam(type: 'full' | 'mini' = 'full') {
    this.GAEvent(type === 'full' ? 'finish_exam' : 'finish_mini_exam', {});
    this.AirbridgeEvent.sendIndividual(
      type === 'full' ? 'airbridge.finish_exam' : 'airbridge.finish_mini_exam',
      {},
    );
  }

  public orderDetailAction(action: string) {
    this.GAEvent('order_detail_action', {
      action,
    });
  }
}
