import { db } from 'firebase-config';
import { doc, getDocFromServer, updateDoc } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

export const getUserById = async (id: string, deviceToken?: string) => {
  const docRef = doc(db, 'User', id);
  const userSnap = await getDocFromServer(docRef);

  let userData: UserData | null = null;

  if (!userSnap.exists()) {
    // User에서 찾을 수 없다면 NonMemberUser에서 찾는다.
    const nonMemberUserSnap = await getDocFromServer(doc(db, 'NonMemberUser', id));
    if (!nonMemberUserSnap.exists()) return null;
    userData = nonMemberUserSnap.data() as UserData;
  } else {
    userData = userSnap.data() as UserData;
  }

  findMethodAndReplace(userData, 'toDate');

  // deviceToken update (모바일 앱 용)
  if (deviceToken && (!userData?.token || userData?.token !== deviceToken)) {
    updateDoc(docRef, {
      token: deviceToken,
      isNotiGranted: window.isNotiGranted ?? 'undefined',
    });
  }
  return { ...userData, uid: id } as UserData;
};
