import type { FC, HTMLAttributes } from 'react';

import { NewIcon } from 'design-system';
import { db } from 'firebase-config';
import { doc, setDoc } from 'firebase/firestore';
import { useRouter } from 'next/router';
import { KAKAO_REDIRECT_URI } from 'shared-values/src/constants/uri';
import { cn } from 'tailwind-config/utils/utils';
import { v4 as uuidv4 } from 'uuid';

import { Loading } from '@components/Loading';
import { useLoginStore } from '@templates/Login';
import { useProcessLoginCallback } from '@templates/Login/actions/use-process-login-callback';

import { isAppApproaching } from '@utils/Common/is-app-approaching';

const decodeData = (data: Record<string, string>) => {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, decodeURIComponent(value)]),
  );
};

const saveLoginState = async (loginState: LoginState, id: string) => {
  await setDoc(doc(db, 'LoginState', id), decodeData(loginState as Record<string, string>));
};

const loginStateId = uuidv4();

export const KakaoLoginButton: FC<
  HTMLAttributes<HTMLButtonElement> & {
    hideOnApp?: boolean;
    redirectUrl?: string;
    onFinish?: () => void;
  }
> = ({ id, className, redirectUrl, onClick, onFinish }) => {
  const router = useRouter();
  const { setIsLoading, isLoading } = useLoginStore();
  useProcessLoginCallback({
    overrideRedirectUrl: redirectUrl ?? router.asPath,
  });

  return (
    <>
      {isLoading && <Loading />}
      <button
        id={id}
        type="button"
        aria-label="카카오 로그인"
        onClick={async (e) => {
          onClick && onClick(e);
          setIsLoading(true);
          if (isAppApproaching()) {
            await window.flutter_inappwebview.callHandler('KakaoLogin');
            // 앱에서 await 하지 않는 문제가 있어 setTimeout으로 대체
            setTimeout(() => {
              setIsLoading(false);
              onFinish && onFinish();
            }, 4000);
          } else {
            await saveLoginState(
              {
                ...(router.query as unknown as LoginState),
                redirectUrl: redirectUrl ?? router.asPath,
              },
              loginStateId,
            );
            await router.replace(
              `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.NEXT_PUBLIC_KAKAO_LOGIN_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code&state=${loginStateId}`,
            );
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          }
        }}
        className={cn(
          'relative flex w-full items-center justify-center gap-16 rounded-[14px] bg-[#FEEC42] py-16',
          className,
        )}
      >
        <NewIcon icon="kakao-20" className="fill-new-gray-900" size={20} />
        <p className="text-new-Body1-bold">카카오로 3초만에 시작하기</p>
      </button>
    </>
  );
};
