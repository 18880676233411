'use client';

import { useEffect, useState } from 'react';

import { QUERY_KEY } from '@db/constants';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { auth, db } from 'firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { isEmpty } from 'lodash-es';

import { getUserById } from '../calls';
import { User } from '../models';

let saveUserId = '';

export const useGetUserId = () => {
  const [userId, setUserId] = useState(saveUserId);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const unlisten = onAuthStateChanged(auth, (fbUser) => {
      setUserId(fbUser?.uid || '');
      saveUserId = fbUser?.uid || '';
      setIsInitialLoading(false);
    });
    return () => {
      unlisten();
    };
  }, []);

  return { userId, isInitialLoading };
};

export const useGetRealtimeUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isUserLoading, setIsUserLoading] = useState(true); // 로딩 상태 추가

  const { userId, isInitialLoading } = useGetUserId();

  const queryClient = useQueryClient();
  const queryKey = [QUERY_KEY.CURRENT_USER, userId];

  useEffect(() => {
    if (!userId) return setIsUserLoading(false);

    setIsUserLoading(true); // 로딩 시작

    const unsubscribe = onSnapshot(doc(db, 'User', userId), (doc) => {
      if (doc.exists()) {
        const user = new User({
          ...(doc.data() as UserData),
          uid: doc.id,
          queryClient,
          queryKey,
        });

        setUser(user);
      } else {
        setUser(null); // 문서가 없을 경우 user를 null로 설정
      }

      setIsUserLoading(false); // 로딩 완료
    });

    return () => unsubscribe();
  }, [userId]);

  // 로딩 상태 추가 반환
  return { user, isLoading: isInitialLoading || isUserLoading };
};

export const useGetUser = <T extends boolean = false>() => {
  const queryClient = useQueryClient();
  const { userId, isInitialLoading } = useGetUserId();

  const queryKey = [QUERY_KEY.CURRENT_USER, userId];

  const result = useQuery<User | null>({
    queryKey,
    queryFn: async () => {
      const [, userId] = queryKey as [string, string];
      if (isEmpty(userId)) return null;

      const deviceToken = window.deviceToken;
      const firestoreUser = await getUserById(userId, deviceToken);

      // email 필드는 무조건 있다고 가정. Firebase Login 시에 auth를 먼저하고 document 생성을 나중에 하기 때문에
      // 빈 유저가 들어오는 경우가 있어서 추가함.
      if (!firestoreUser) return null;

      const user = new User({
        ...firestoreUser,
        queryClient,
        queryKey,
      });

      if (user.isManager) {
        const cookieName = 'gtm_internal_user'; // Name of your cookie
        const cookieValue = 'internal'; // Value of your cookie
        let expirationTime = 31104000; // One year in seconds
        expirationTime = expirationTime * 1000; // Converts expiration time to milliseconds
        const date = new Date();
        const dateTimeNow = date.getTime();

        date.setTime(dateTimeNow + expirationTime); // Sets expiration time (Time now + one year)
        const expiryDate = date.toUTCString(); // Converts milliseconds to UTC time string
        document.cookie =
          `${cookieName}=${cookieValue}; SameSite=None; Secure; expires=${expiryDate}; path=/; domain=.` +
          window.location.hostname.replace(/^www\./i, ''); // Sets cookie for all subdomains
      }

      return user;
    },
    staleTime: 0,
    gcTime: 0,
    refetchInterval: 1000 * 60,
    refetchIntervalInBackground: true,
  });

  const { data: user } = result;

  const setUser = (userData: UserData) => {
    const userModel = new User({ ...userData, queryClient, queryKey });
    queryClient.setQueryData<User>(queryKey, userModel);
    return userModel;
  };

  const resetUser = async () => await queryClient.resetQueries({ queryKey });

  const isUserExist = Boolean(user);

  return {
    ...result,
    isLoading: isInitialLoading || result.isLoading,
    user: user as T extends true ? User : User | undefined,
    setUser,
    resetUser,
    isUserExist,
    refetchUser: result.refetch,
  };
};
