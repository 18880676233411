import axios from 'axios';
import { BACKEND_URL } from 'shared-values';

import { api } from '@apis/hc';

import { EmitReturn } from '@hooks';

import { signUp } from './utils';

export const signUpNewUser = async ({
  phoneNum,
  notificationAgreed,
  email,
  name,
  birthYear,
  firebaseIdToken,
  triggerEmit,
  birthday,
  realName,
  provider,
}: {
  phoneNum: string;
  notificationAgreed: boolean;
  provider: Provider;
  email: string;
  name: string;
  birthYear: string;
  firebaseIdToken: string;
  triggerEmit: () => Promise<EmitReturn<{ phoneNum: string; notificationAgreed: boolean }>>;
  birthday: string;
  realName: string;
}) => {
  // 3. 유저 전화번호 검증
  let submitPhoneNumber = phoneNum;
  let submitNotificationAgreed = notificationAgreed;

  // 일단 전화번호 없는 경우 = notificationAgreed === 'not_decided' 인 경우이므로 정보를 같이 받음
  // 추후 전략이 바뀌면 수정 필요
  if (submitPhoneNumber === '') {
    // 전화번호 없을 경우 추가 정보 받음
    const { result, phoneNum, notificationAgreed = false } = await triggerEmit();

    if (result === 'fail') throw new Error('전화번호 인증에 실패했습니다.');
    submitPhoneNumber = phoneNum;
    submitNotificationAgreed = notificationAgreed;
  }

  // 4. 새로운 유저 회원가입 (Firebase Auth)
  const userId = await signUp({
    email,
    name,
    firebaseIdToken,
  });

  // 5. DB에 유저 정보 저장 (Firestore)
  const result = await api.user['create-user'].$post({
    json: {
      id: userId,
      phoneNum: submitPhoneNumber,
      email,
      notificationAgreed: submitNotificationAgreed,
      // 일단 빈 값으로
      birthYear,
      birthday,
      realName,
      provider,
    },
  });

  const rawUserData = (await result.json()).data;

  const userData = {
    ...rawUserData,
    // string으로 넘어오는 값이라서 따로 변환
    createdAt: new Date(rawUserData.createdAt),
  } as UserData;

  await axios.post(`${BACKEND_URL}/auth/send-message`, {
    phoneNum: submitPhoneNumber,
    provider,
  });

  return { ...userData, uid: userId };
};
