'use client';

import * as React from 'react';

import { isAppApproaching } from 'shared-values';
import { cn } from 'tailwind-config';
import { Drawer as NewBottomSheetPrimitive } from 'vaul';

import { NewIcon } from '../NewIcon';

const NewBottomSheet = ({
  shouldScaleBackground = true,
  ...props
}: React.ComponentProps<typeof NewBottomSheetPrimitive.Root>) => (
  <NewBottomSheetPrimitive.Root
    disablePreventScroll={false}
    shouldScaleBackground={shouldScaleBackground}
    {...props}
  />
);
NewBottomSheet.displayName = 'NewBottomSheet';

const NewBottomSheetTrigger = NewBottomSheetPrimitive.Trigger;

const NewBottomSheetPortal = NewBottomSheetPrimitive.Portal;

const NewBottomSheetClose = NewBottomSheetPrimitive.Close;

const NewBottomSheetOverlay = React.forwardRef<
  React.ElementRef<typeof NewBottomSheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof NewBottomSheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <NewBottomSheetPrimitive.Overlay
    ref={ref}
    className={cn('bg-new-black-50 fixed inset-0 z-[1000]', className)}
    {...props}
  />
));
NewBottomSheetOverlay.displayName = NewBottomSheetPrimitive.Overlay.displayName;

const NewBottomSheetContentInnerContent = React.forwardRef<
  React.ElementRef<typeof NewBottomSheetPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NewBottomSheetPrimitive.Content> & {
    nested?: boolean;
  }
>(({ className, children, nested = false, ...props }, ref) => (
  <NewBottomSheetPrimitive.Content
    ref={ref}
    onClick={(e) => e.stopPropagation()}
    className={cn(
      'fixed inset-x-0 bottom-0 z-[1000] mx-auto flex h-auto max-w-[500px] flex-col rounded-t-[24px] bg-white px-16',
      isAppApproaching({ isIOSApproaching: true }) ? 'pb-[32px]' : 'pb-[10px]',
      className,
    )}
    {...props}
  >
    {children}
  </NewBottomSheetPrimitive.Content>
));

const NewBottomSheetContent = React.forwardRef<
  React.ElementRef<typeof NewBottomSheetPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NewBottomSheetPrimitive.Content>
>(({ children, ...props }, ref) => (
  <NewBottomSheetPortal
    container={typeof document !== 'undefined' ? document.getElementById('portal') : undefined}
  >
    <NewBottomSheetOverlay />
    <NewBottomSheetContentInnerContent {...props}>{children}</NewBottomSheetContentInnerContent>
  </NewBottomSheetPortal>
));
NewBottomSheetContent.displayName = 'NewBottomSheetContent';

const NewBottomSheetHeader = ({
  className,
  closeButton = true,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { closeButton?: boolean }) => (
  <div className="relative">
    <div className={cn('flex flex-col gap-4 py-24', className)} {...props} />
    <NewBottomSheetClose className="absolute right-0 top-[26.5px] cursor-pointer">
      <NewIcon icon="xincircle-filled" size={24} className="fill-new-gray-400" />
    </NewBottomSheetClose>
  </div>
);
NewBottomSheetHeader.displayName = 'NewBottomSheetHeader';

const NewBottomSheetFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('mt-auto flex flex-col gap-2 p-0 pt-[10px]', className)} {...props} />
);
NewBottomSheetFooter.displayName = 'NewBottomSheetFooter';

const NewBottomSheetTitle = React.forwardRef<
  React.ElementRef<typeof NewBottomSheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof NewBottomSheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <NewBottomSheetPrimitive.Title
    ref={ref}
    className={cn('text-new-Sub-Title', className)}
    {...props}
  />
));
NewBottomSheetTitle.displayName = NewBottomSheetPrimitive.Title.displayName;

const NewBottomSheetDescription = React.forwardRef<
  React.ElementRef<typeof NewBottomSheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof NewBottomSheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <NewBottomSheetPrimitive.Description
    ref={ref}
    className={cn('text-new-Body2-medium text-new-gray-500', className)}
    {...props}
  />
));
NewBottomSheetDescription.displayName = NewBottomSheetPrimitive.Description.displayName;

export {
  NewBottomSheet,
  NewBottomSheetClose,
  NewBottomSheetContent,
  NewBottomSheetContentInnerContent,
  NewBottomSheetDescription,
  NewBottomSheetFooter,
  NewBottomSheetHeader,
  NewBottomSheetOverlay,
  NewBottomSheetPortal,
  NewBottomSheetTitle,
  NewBottomSheetTrigger,
};
