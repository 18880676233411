import { db } from 'firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { findMethodAndReplace } from 'shared-values';

import { getAcademyData } from '../../Academy';
import { SalesAcademy } from '../models';

export const getSalesAcademyData = async (id: string) => {
  const docRef = doc(db, 'SalesAcademy', id);
  const docData = (await getDoc(docRef)).data();

  findMethodAndReplace(docData, 'toDate');

  return { ...docData, id: docRef.id } as SalesAcademyData;
};

export const getSalesAcademy = async ({ queryKey, queryClient }: QueryFn) => {
  const [, id] = queryKey as [string, string];

  const docData = await getSalesAcademyData(id);

  const academyData = await getAcademyData(id);

  return new SalesAcademy({ ...academyData, ...docData, queryClient, queryKey });
};
