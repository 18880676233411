export const 후기최대갯수 = 30;

export const 단기취득지역 = [
  {
    sido: '경기도',
    sigungu: '안양시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '경기도',
    sigungu: '안산시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '경기도',
    sigungu: '광명시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '경기도',
    sigungu: '군포시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  { sido: '경기도', sigungu: '부천시', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '경기도', sigungu: '시흥시', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '경기도', sigungu: '김포시', academyId: 'Grp2b7xJ160QFafLRb9C' },
  {
    sido: '경기도',
    sigungu: '의왕시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '경기도',
    sigungu: '고양시',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '삼송역',
    pickup: '삼송역 4번 출구 앞',
    durationTime: '20분',
  },
  {
    sido: '경기도',
    sigungu: '파주시',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '삼송역',
    pickup: '삼송역 4번 출구 앞',
    durationTime: '30분',
  },
  { sido: '인천광역시', sigungu: '중구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '인천광역시', sigungu: '동구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '인천광역시', sigungu: '미추홀구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '인천광역시', sigungu: '연수구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '인천광역시', sigungu: '남동구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '인천광역시', sigungu: '부평구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '인천광역시', sigungu: '계양구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '인천광역시', sigungu: '서구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '인천광역시', sigungu: '옹진군', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '서울특별시', sigungu: '강서구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '서울특별시', sigungu: '양천구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '서울특별시', sigungu: '구로구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '서울특별시', sigungu: '금천구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '서울특별시', sigungu: '영등포구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  {
    sido: '서울특별시',
    sigungu: '관악구',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '서울특별시',
    sigungu: '동작구',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '서울특별시',
    sigungu: '용산구',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  { sido: '서울특별시', sigungu: '마포구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '서울특별시', sigungu: '서대문구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  { sido: '서울특별시', sigungu: '은평구', academyId: 'Grp2b7xJ160QFafLRb9C' },
  {
    sido: '서울특별시',
    sigungu: '종로구',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '한성대입구역',
    pickup: '한성대입구역 1번 출구 앞',
    durationTime: '40분',
  },
  {
    sido: '서울특별시',
    sigungu: '중구',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '한성대입구역',
    pickup: '한성대입구역 1번 출구 앞',
    durationTime: '40분',
  },
  {
    sido: '경기도',
    sigungu: '수원시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '경기도',
    sigungu: '용인시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '경기도',
    sigungu: '성남시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '잠실역',
    pickup: '잠실역 10번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '경기도',
    sigungu: '과천시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '경기도',
    sigungu: '광주시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '미사역',
    pickup: '미사역 7번 출구 앞',
    durationTime: '20분',
  },
  {
    sido: '경기도',
    sigungu: '하남시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '미사역',
    pickup: '미사역 7번 출구 앞',
    durationTime: '20분',
  },
  {
    sido: '경기도',
    sigungu: '구리시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '호평역',
    pickup: '호평 봉구스',
    durationTime: '20분',
  },
  {
    sido: '경기도',
    sigungu: '남양주시',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '호평역',
    pickup: '호평 봉구스',
    durationTime: '20분',
  },
  {
    sido: '경기도',
    sigungu: '동두천시',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '양주역',
    durationTime: '10분',
  },
  {
    sido: '경기도',
    sigungu: '양주시',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '양주 전역',
    pickup: '양주 전역 (전지역 픽업 가능)',
    durationTime: '10분',
  },
  {
    sido: '경기도',
    sigungu: '의정부시',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '의정부 전역',
    pickup: '의정부 전역 (전지역 픽업 가능)',
    durationTime: '20분',
  },
  {
    sido: '경기도',
    sigungu: '포천시',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '양주역',
    pickup: '양주 전역 (전지역 픽업 가능)',
    durationTime: '10분',
  },
  {
    sido: '서울특별시',
    sigungu: '도봉구',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '창동역',
    pickup: '창동역 이마트',
    durationTime: '30분',
  },
  {
    sido: '서울특별시',
    sigungu: '강북구',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '수유역',
    pickup: '수유역 4번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '서울특별시',
    sigungu: '노원구',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '노원역',
    pickup: '노원역 5번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '서울특별시',
    sigungu: '중랑구',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '군자역',
    pickup: '군자역 5번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '서울특별시',
    sigungu: '광진구',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '광나루역',
    pickup: '광나루역 3번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '서울특별시',
    sigungu: '동대문구',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '회기역',
    pickup: '회기역 사거리',
    durationTime: '40분',
  },
  {
    sido: '서울특별시',
    sigungu: '성북구',
    academyId: 'Grp2b7xJ160QFafLRb9C',
    stationName: '고려대역',
    pickup: '고려대역 3번 출구 앞',
    durationTime: '40분',
  },
  {
    sido: '서울특별시',
    sigungu: '성동구',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '광나루역',
    pickup: '광나루역 3번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '서울특별시',
    sigungu: '강동구',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '천호역',
    pickup: '천호역 1번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '서울특별시',
    sigungu: '송파구',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '잠실역',
    pickup: '잠실역 10번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '서울특별시',
    sigungu: '강남구',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '서울특별시',
    sigungu: '서초구',
    academyId: '1yyMKNljZeSCdsTbBp2i',
    stationName: '삼성역',
    pickup: '삼성역 1번 출구 앞',
    durationTime: '30분',
  },
  {
    sido: '경상남도',
    sigungu: '양산시',
    academyId: 'H1tKxFz37lAesJJBfSl4',
  },
  {
    sido: '경상북도',
    sigungu: '경주시',
    academyId: 'H1tKxFz37lAesJJBfSl4',
  },
  {
    sido: '경상북도',
    sigungu: '영천시',
    academyId: 'H1tKxFz37lAesJJBfSl4',
  },
  {
    sido: '경상북도',
    sigungu: '포항시',
    academyId: 'H1tKxFz37lAesJJBfSl4',
  },
  {
    sido: '부산광역시',
    sigungu: '기장군',
    academyId: 'H1tKxFz37lAesJJBfSl4',
  },
  {
    sido: '울산광역시',
    sigungu: '남구',
    academyId: 'H1tKxFz37lAesJJBfSl4',
  },
  {
    sido: '울산광역시',
    sigungu: '동구',
    academyId: 'H1tKxFz37lAesJJBfSl4',
  },
  {
    sido: '울산광역시',
    sigungu: '북구',
    academyId: 'H1tKxFz37lAesJJBfSl4',
  },
  {
    sido: '울산광역시',
    sigungu: '울주군',
    academyId: 'H1tKxFz37lAesJJBfSl4',
  },
  {
    sido: '울산광역시',
    sigungu: '동구',
    academyId: 'H1tKxFz37lAesJJBfSl4',
  },
  {
    sido: '경상북도',
    sigungu: '구미시',
    academyId: 'EahQ1kTpvK2NQUI3huoQ4',
  },
  {
    sido: '경상북도',
    sigungu: '칠곡군',
    academyId: 'EahQ1kTpvK2NQUI3huoQ4',
  },
  {
    sido: '경상북도',
    sigungu: '의성군',
    academyId: 'EahQ1kTpvK2NQUI3huoQ4',
  },
  {
    sido: '대구광역시',
    sigungu: '군위군',
    academyId: 'EahQ1kTpvK2NQUI3huoQ4',
  },
  {
    sido: '대구광역시',
    sigungu: '남구',
    academyId: 'EahQ1kTpvK2NQUI3huoQ4',
  },
  {
    sido: '대구광역시',
    sigungu: '달서구',
    academyId: 'EahQ1kTpvK2NQUI3huoQ4',
  },
  {
    sido: '대구광역시',
    sigungu: '달성군',
    academyId: 'EahQ1kTpvK2NQUI3huoQ4',
  },
  {
    sido: '대구광역시',
    sigungu: '동구',
    academyId: 'EahQ1kTpvK2NQUI3huoQ4',
  },
  {
    sido: '대구광역시',
    sigungu: '북구',
    academyId: 'EahQ1kTpvK2NQUI3huoQ4',
  },
  {
    sido: '대구광역시',
    sigungu: '서구',
    academyId: 'EahQ1kTpvK2NQUI3huoQ4',
  },
  {
    sido: '대구광역시',
    sigungu: '수성구',
    academyId: 'EahQ1kTpvK2NQUI3huoQ4',
  },
  {
    sido: '대구광역시',
    sigungu: '중구',
    academyId: 'EahQ1kTpvK2NQUI3huoQ4',
  },
  {
    sido: '경상남도',
    sigungu: '진주시',
    academyId: 'IdiE9ngLqYWDY4MW0Td0',
  },
  {
    sido: '경상남도',
    sigungu: '사천시',
    academyId: 'IdiE9ngLqYWDY4MW0Td0',
  },
  {
    sido: '경상남도',
    sigungu: '남해군',
    academyId: 'IdiE9ngLqYWDY4MW0Td0',
  },
  {
    sido: '경상남도',
    sigungu: '고성군',
    academyId: 'IdiE9ngLqYWDY4MW0Td0',
  },
];

export const 단기취득학원IDs = [...new Set(단기취득지역.map((item) => item.academyId))];
