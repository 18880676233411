import React, { HTMLAttributes, useState } from 'react';

import { useGetUser } from '@db/collections';
import {
  NewBottomSheet,
  NewBottomSheetContentInnerContent,
  NewBottomSheetDescription,
  NewBottomSheetHeader,
  NewBottomSheetOverlay,
  NewBottomSheetPortal,
  NewBottomSheetTitle,
  NewBottomSheetTrigger,
  Spacer,
} from 'design-system';
import Image from 'next/image';

import { KakaoLoginButton } from '@components/Activation/KakaoLoginButton';

export const NeedLoginBottomSheet = ({
  children,
  className,
  redirectUrl,
  title = '로그인하고 참여하기',
  description = '쿠폰을 받으려면, 로그인/회원가입이 필요해요.',
  allowNonMember = false,
  disable = false,

  ...props
}: HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  className?: string;
  redirectUrl?: string;
  title?: string;
  description?: string;
  allowNonMember?: boolean;
  disable?: boolean;
}) => {
  const { user, isLoading } = useGetUser();
  const [isOpen, setIsOpen] = useState(false);

  if (disable || (allowNonMember && user) || (user && user.provider !== 'non-member') || isLoading)
    return <div {...props}>{children}</div>;

  return (
    <NewBottomSheet open={isOpen} onOpenChange={setIsOpen}>
      <NewBottomSheetTrigger asChild>{children}</NewBottomSheetTrigger>
      <NewBottomSheetPortal
        container={typeof document !== 'undefined' ? document.getElementById('portal') : undefined}
      >
        {/* 
          BottomSheet가 Nested하게 사용됐을 떄, 한개의 BottomSheet만 작동하도록 함
          하나의 BottomSheet가 다른 BottomSheet의 Trigger가 되는 동작을 막음.
        */}
        <NewBottomSheetOverlay onClick={(e) => e.stopPropagation()} />
        <NewBottomSheetContentInnerContent onClick={(e) => e.stopPropagation()}>
          <NewBottomSheetHeader>
            <NewBottomSheetTitle>{title}</NewBottomSheetTitle>
            <NewBottomSheetDescription className="text-new-Body2-medium text-new-gray-500">
              {description}
            </NewBottomSheetDescription>
          </NewBottomSheetHeader>
          <div className="flex w-full items-center justify-center">
            <Image src="/need-login.png" width={96} height={96} alt="로그인 필요" />
          </div>
          <Spacer className="h-[24px]" />
          <KakaoLoginButton redirectUrl={redirectUrl} onFinish={() => setIsOpen(false)} />
        </NewBottomSheetContentInnerContent>
      </NewBottomSheetPortal>
    </NewBottomSheet>
  );
};
