import { DisplayAcademy } from '@models';
import { distanceBetween } from 'geofire-common';

import { ExploreMapAcademy } from '@templates/Explore/Explore/hooks/use-get-explore-academies/use-get-explore-academies';

import { BaseLogEvent, BaseParams } from '../BaseLogEvent';

interface ExploreParams {
  explore_academy_type: AcademyTabType;
  explore_view: ExploreViewType;
  explore_conversion?: ExploreConversionEvent;
  distance_from?: string;
}

export type ExploreConversionEvent =
  | 'marker_click'
  | 'marker_double_click'
  | 'marker_detail_click'
  | 'marker_reservation_click'
  | 'list_click';

export class 활성화 extends BaseLogEvent {
  constructor(params: BaseParams) {
    super(params);
  }

  public $상품조회(academy: Academy) {
    this.GAEvent('view_item', {
      ...this.params.ecommerce.getGAAcademyParams(academy, this.location),
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getDistanceParams(this.location, academy.location),
    });
    this.FlarelaneEvent.setTags({
      last_view_academy_id: academy.id,
      last_view_academy_name: academy.name,
    });

    this.AirbridgeEvent.productDetailsViewEvent(
      this.params.ecommerce.getAirbridgeAcademyParams(academy),
    );

    this.NaverEvent.trackEvent({
      type: 'view_content',
      id: '',
      items: [],
      value: '',
    });
  }

  public lessonDetailConversion(
    conversion: 'solo_reservation' | 'together_reservation',
    academy: Academy,
  ) {
    const lessonDetailConversionParams = {
      lesson_detail_conversion: conversion,
      ...this.params.common.getAcademyParams(academy),
    };

    this.GAEvent('lesson_detail_conversion', lessonDetailConversionParams);

    if (conversion === 'solo_reservation') {
      this.AirbridgeEvent.sendIndividual('lesson-detail-solo-reservation-click', {});
    } else if (conversion === 'together_reservation') {
      this.AirbridgeEvent.sendIndividual('lesson-detail-together-reservation-click', {});
    }
  }

  public academyDetailConversion(
    conversion: 'solo_reservation' | 'together_reservation' | 'lesson_detail',
    academy: Academy,
  ) {
    const academyDetailConversionParams = {
      action: conversion,
      ...this.params.common.getAcademyParams(academy),
    };
    this.GAEvent('academy_detail_conversion', academyDetailConversionParams);
    if (conversion === 'solo_reservation') {
      this.AirbridgeEvent.sendIndividual('academy-detail-solo-reservation-click', {});
    } else if (conversion === 'together_reservation') {
      this.AirbridgeEvent.sendIndividual('academy-detail-together-reservation-click', {});
    }
  }

  public academyDetailTabs(tab: string, academy: Academy) {
    this.GAEvent('academy_detail_tabs', {
      label: tab,
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getDistanceParams(this.location, academy.location),
    });
    this.posthog.capture('academy_detail_tabs', {
      label: tab,
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getDistanceParams(this.location, academy.location),
    });
  }

  public academyDetailEventBannerClick(action: string) {
    this.GAEvent('academy_detail_event_banner_click', {
      action,
    });
  }

  public academyDetailAction(
    action:
      | '학원 이미지 크게 보기'
      | '학원 설명 펼치기'
      | '학원 전화'
      | '주소 복사'
      | '웹사이트'
      | '운영시간 펼치기'
      | '쿠폰 받기'
      | '부가정보 칩 클릭'
      | '최저가 보상제'
      | '수업 시간표 월 변경'
      | '수업 시간표 일 클릭'
      | '수업정보 더보기'
      | '리뷰 카드 클릭'
      | '블로그 사진 클릭'
      | '블로그 클릭'
      | '리뷰 더보기'
      | '소개 영상 클릭'
      | '셔틀버스 더보기' /* deprecated */
      | '셔틀지역 더보기' /* deprecated */
      | '셔틀노선도 이미지 크게 보기' /* deprecated */
      | '셔틀지역보기 버튼 클릭'
      | '셔틀지도 확대 버튼 클릭'
      | '기본정보 셔틀지도 이동'
      | '지도로 이동'
      | '코치 마크 닫기'
      | '시뮬레이션 수업 상세 상품 변경'
      | '수업 상세 블로그 이동'
      | '기본정보 수업 상세 이동'
      | '기본정보 카드혜택 클릭'
      | '기본정보 쿠폰헤택 클릭'
      | '기본정보 후기 별점 클릭'
      | '기본정보 후기 전체보기 클릭'
      | '기본정보 후기 아이템 클릭'
      | '기본정보 후기 더보기 클릭',
    academy?: Academy,
  ) {
    this.GAEvent('academy_detail_action', {
      action: action,
      ...this.params.common.getAcademyParams(academy),
    });

    this.posthog.capture('academy_detail_action', {
      action: action,
      ...this.params.common.getAcademyParams(academy),
    });
  }

  public academyDetailPageView(academy: Academy) {
    this.GAEvent('pv_academy_detail', {
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getDistanceParams(this.location, academy.location),
    });
  }

  public academyLessonDetailPageView(academy: Academy, lesson: Lesson) {
    this.GAEvent('pv_academy_lesson_detail', {
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getLessonParams(lesson),
      ...this.params.common.getDistanceParams(this.location, academy.location),
    });
  }

  public bookmark(
    academy: Academy | DisplayAcademy,
    action: 'add' | 'remove',
    bookmark_position: string,
  ) {
    const { label } =
      academy instanceof DisplayAcademy
        ? academy.lesson
        : academy.selectLesson({ code: 'TWO_AUTO_NEW' });

    const bookmarkParams = {
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getDistanceParams(this.location, academy.location),
      lesson_name: label,
      action: action,
      position: bookmark_position,
    };
    this.GAEvent('bookmark', bookmarkParams);
  }

  public $상품리스트조회(
    academies: DisplayAcademy[] | ExploreMapAcademy[],
    exploreParams: ExploreParams,
  ) {
    this.GAEvent('view_item_list', {
      ...this.params.ecommerce.getGAAcademyParams(academies, this.location),
      ...exploreParams,
    });

    this.NaverEvent.trackEvent({
      type: 'view_item_list',
      id: '',
      items: [],
      value: '',
    });

    const airbridgeOption = this.params.ecommerce.getAirbridgeAcademyParams(academies);
    this.AirbridgeEvent.productListViewEvent(airbridgeOption);

    if (this.location && academies.length > 0) {
      const nearestAcademy = academies.toSorted((a, b) => {
        if (!a.location || !b.location || !this.location) return 0;

        const aDistance = distanceBetween(
          [a.location.latitude, a.location.longitude],
          [this.location.latitude, this.location.longitude],
        );

        const bDistance = distanceBetween(
          [b.location.latitude, b.location.longitude],
          [this.location.latitude, this.location.longitude],
        );

        return aDistance - bDistance;
      })[0];

      this.FlarelaneEvent.setTags({
        nearest_academy_id: nearestAcademy.id,
        nearest_academy_name: nearestAcademy.name,
      });
    }
  }
  public $상품선택(
    academy: DisplayAcademy | Academy | ExploreMapAcademy,
    exploreParams: ExploreParams,
  ) {
    const selectItemParams = {
      ...this.params.ecommerce.getGAAcademyParams([academy], this.location),
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getDistanceParams(this.location, academy.location),
      ...exploreParams,
    };

    this.GAEvent('select_item', selectItemParams);
  }

  public academyTypeChange(explore_academy_type: AcademyTabType, exploreParams: object) {
    const academyTypeChangeParams = {
      ...exploreParams,
      academy_type_change: explore_academy_type,
      explore_academy_type: explore_academy_type,
    };
    this.GAEvent('academy_type_change', academyTypeChangeParams);
  }

  public exploreAction(action: '코치 마크 닫기', exploreParams: ExploreParams) {
    this.GAEvent('explore_action', {
      action,
      ...exploreParams,
    });
  }

  public exploreConversion(
    explore_conversion: ExploreConversionEvent,
    academy: DisplayAcademy | Academy | ExploreMapAcademy,
    exploreParams: ExploreParams,
  ) {
    const exploreConversionParams = {
      ...exploreParams,
      explore_conversion: explore_conversion,
      ...this.params.common.getAcademyParams(academy),
      ...this.params.common.getDistanceParams(this.location, academy.location),
    };
    this.GAEvent('explore_conversion', exploreConversionParams);
    if (explore_conversion === 'marker_reservation_click') {
      this.AirbridgeEvent.sendIndividual('explore-reservation-click', {});
    } else if (explore_conversion === 'marker_detail_click') {
      this.AirbridgeEvent.sendIndividual('explore-detail-click', {});
    }
  }

  public exploreFilter(explore_filter: any, exploreParams: ExploreParams) {
    this.GAEvent('explore_filter', {
      explore_filter: explore_filter,
      ...exploreParams,
    });
  }

  public exploreFilterDetail(explore_filter_detail: any, exploreParams: ExploreParams) {
    this.GAEvent('explore_filter_detail', {
      explore_filter_detail: explore_filter_detail,
      ...exploreParams,
    });
  }

  public exploreViewChange = (explore_view: ExploreViewType, exploreParams: ExploreParams) => {
    this.GAEvent('explore_view_change', {
      ...exploreParams,
      explore_view_change: explore_view,
    });
  };

  public explorePV(exploreParams: ExploreParams) {
    this.GAEvent('explore_pv', {
      ...exploreParams,
    });
  }

  public exploreResult(parameter: object) {
    this.AirbridgeEvent.searchResultViewEvent(parameter);
  }

  public homeCTA(action: string) {
    this.GAEvent('home_cta', {
      action,
    });

    this.AirbridgeEvent.sendIndividual('home_cta', {
      action,
    });

    this.posthog.capture('home_cta', {
      action,
    });
  }

  public homeMainCTA(label: string) {
    this.GAEvent('home_main_cta', {
      label,
    });

    this.AirbridgeEvent.sendIndividual('home_main_cta', {
      label,
    });

    this.posthog.capture('home_main_cta', {
      label,
    });
  }

  public homeBanner(label: string) {
    this.GAEvent('home_event_banner', {
      label,
    });

    this.AirbridgeEvent.sendIndividual('home_event_banner', {
      label,
    });

    this.posthog.capture('home_event_banner', {
      label,
    });
  }

  public recommendEventGotoAcademyClick(action: string) {
    this.GAEvent('event.24ss.recommend_academy.go_to_academy_click', {
      action,
    });
    this.AirbridgeEvent.sendIndividual('event.24ss.recommend_academy.go_to_academy_click', {
      action,
    });
  }

  public recommendEventGetCouponClick(action: string) {
    this.GAEvent('event.24ss.recommend_academy.get_coupon_click', {
      action,
    });
    this.AirbridgeEvent.sendIndividual('event.24ss.recommend_academy.get_coupon_click', {
      action,
    });
  }

  public phoneNumClick(academy: Academy) {
    this.GAEvent('phone_num_click', {
      academy_id: academy.id,
      academy_name: academy.locationAttachedName,
    });
  }

  public inquiryClick(academy?: Academy) {
    this.GAEvent('inquiry_click', {
      academy_id: academy ? academy.id : '',
      academy_name: academy ? academy.locationAttachedName : '',
    });
  }

  public 비입점탐색이동(action: 'button' | 'banner') {
    this.GAEvent('비입점탐색이동', {
      action,
    });

    this.AirbridgeEvent.sendIndividual('비입점탐색이동', {
      action,
    });
  }
  public testAction(action: string) {
    this.GAEvent('test_action', {
      action,
    });
  }

  public todayProblem(action: string, label?: string) {
    this.GAEvent('today_problem', {
      action,
      label,
    });

    this.AirbridgeEvent.sendIndividual('today_problem', {
      action,
      label,
    });
  }

  public pcWebToApp(action: string) {
    this.GAEvent('pc_web_to_app', {
      action,
    });

    this.AirbridgeEvent.sendIndividual('pc_web_to_app', {
      action,
    });
  }
  public webToAppButtonClick(label: string) {
    this.GAEvent('web_to_app_button_click', {
      label,
    });

    this.NaverEvent.trackEvent({
      type: 'custom001',
      id: '',
      items: [],
      value: '',
    });

    this.AirbridgeEvent.sendIndividual('web_to_app_button_click', {
      label,
    });
  }

  public blogToExplore(action: string) {
    this.GAEvent('blog_to_explore', {
      action,
    });

    this.AirbridgeEvent.sendIndividual('blog_to_explore', {
      action,
    });
  }

  public togetherEvent(action: string) {
    this.GAEvent('together_event', {
      action,
    });

    this.AirbridgeEvent.sendIndividual('together_event', {
      action,
    });
  }

  public sectionView(
    sectionName: string,
    seconds: string,
    academyData: Academy | undefined,
    isTest?: boolean,
  ) {
    this.GAEvent('section_view', {
      section: sectionName,
      seconds,
      ...this.params.common.getAcademyParams(academyData),
      ...(isTest !== undefined
        ? {
            isTest,
          }
        : {}),
    });
  }

  public sectionExit(
    sectionName: string,
    seconds: string,
    academyData: Academy | undefined,
    isTest?: boolean,
  ) {
    //  seconds가 5분을 넘으면 제외
    if (Number(seconds) > 300) return;

    this.GAEvent('section_exit', {
      section: sectionName,
      seconds: Number(seconds),
      ...this.params.common.getAcademyParams(academyData),
      ...(isTest !== undefined
        ? {
            isTest,
          }
        : {}),
    });
  }

  public addressEnter(action: string) {
    this.GAEvent('address_enter', {
      action,
    });

    this.AirbridgeEvent.sendIndividual('address_enter', {
      action,
    });

    this.posthog.capture('address_enter', {
      action,
    });
  }
  public nearestShuttle(action: string) {
    this.GAEvent('nearest_shuttle', {
      action,
    });

    this.AirbridgeEvent.sendIndividual('nearest_shuttle', {
      action,
    });

    this.posthog.capture('nearest_shuttle', {
      action,
    });
  }
}
