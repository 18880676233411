import { BaseModel } from '@db/base-models';

export class DetailInfo extends BaseModel {
  CEOId: string;
  CEOMail: string;
  CEOName: string;
  CEOPhone: string;
  academyRegNum: string;
  businessRegNum: string;
  companyRegNum: string;
  introduce: string;
  notice: string;
  faxNum?: string;
  kakaoNumbers?: string[];
  erpEmail?: string;

  constructor({
    CEOId,
    CEOMail,
    CEOName,
    CEOPhone,
    academyRegNum,
    businessRegNum,
    companyRegNum,
    introduce,
    notice,
    faxNum,
    kakaoNumbers,
    erpEmail,
  }: DetailInfoData) {
    super('DetailInfo');
    this.CEOId = CEOId;
    this.CEOMail = CEOMail;
    this.CEOName = CEOName;
    this.CEOPhone = CEOPhone;
    this.academyRegNum = academyRegNum;
    this.businessRegNum = businessRegNum;
    this.companyRegNum = companyRegNum;
    this.introduce = introduce;
    this.notice = notice;
    this.faxNum = faxNum;
    this.kakaoNumbers = kakaoNumbers;
    this.erpEmail = erpEmail;
  }
}
