import { db } from 'firebase-config';
import { collection, documentId, getDocs, query, where } from 'firebase/firestore';
import { chunkArray } from 'shared-values';

import { Exercise, ExerciseList } from '../models';

export const getExercisesByIndexes = async ({ queryClient, queryKey }: QueryFn) => {
  const [, indexes] = queryKey as [string, number[]];

  let result: Exercise[] = [];

  if (indexes.length === 0) {
    return new ExerciseList({ items: [], queryClient, queryKey });
  }

  const chunks = chunkArray(indexes, 10);

  for await (const chunk of chunks) {
    const q = query(
      collection(db, 'Problems2025'),
      where(
        documentId(),
        'in',
        chunk.map((v) => v.toString()),
      ),
    );
    const querySnapshot = await getDocs(q);
    const newArray: Exercise[] = [];
    querySnapshot.forEach((docQuery) => {
      const docData = { ...docQuery.data(), id: docQuery.id } as ExerciseData;
      newArray.push(new Exercise(docData));
    });

    // chunk에 있는 index 순서대로 정렬
    newArray.sort((a, b) => chunk.indexOf(a.index) - chunk.indexOf(b.index));

    result = [...result, ...newArray];
  }

  return new ExerciseList({ items: result, queryClient, queryKey });
};
