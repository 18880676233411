import { FC, useEffect, useMemo, useState } from 'react';

import { useGetUser } from '@db/collections';
import * as RadixDialog from '@radix-ui/react-dialog';
import { addDays, differenceInMinutes } from 'date-fns';
import {
  BOXBUTTON_COLOR,
  BoxButton,
  NewBottomSheet,
  NewBottomSheetContent,
  NewBottomSheetDescription,
  NewBottomSheetHeader,
  NewBottomSheetTitle,
  NewBottomSheetTrigger,
  NewIcon,
  Spacer,
  useOverlay,
  useToast,
} from 'design-system';
import { db } from 'firebase-config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAtom } from 'jotai';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { cn } from 'tailwind-config';

import {
  MarketingAgreementBottomSheet,
  MarketingAgreementOption,
} from '@components/MarketingAgreementBottomSheet/MarketingAgreementBottomSheet';
import { notificationStateAtom } from '@components/MarketingAgreementBottomSheet/hooks/use-notification-agree';

import { BlogReaderSignUpEventResultModal } from './BlogReaderSignUpEventModals';
import { KakaoLoginButton } from './KakaoLoginButton';

const BLOG_READER_COUPON_CODE = 'BLOGREADERSIGNUP';
const ELIGIBILITY_MINUTES = 10;
const COUPON_EXPIRATION_DAYS = 30;
const BLOG_POST_EVENT_LOCAL_STORAGE_KEY = 'blog-post-event';
const BLOG_COUPON_ISSUED_LOCAL_STORAGE_KEY = 'blog-coupon-issued';
const BLOG_COUPON_MARKETING_OPTION: MarketingAgreementOption = {
  marketingAgree: 'required',
  notificationAgree: 'required',
  kakaoChannelAgree: 'not-needed',
  verificationAgree: 'not-needed',
};

const isBlogReader = (): boolean => {
  return sessionStorage.getItem('is-blog-event-target') === 'true';
};

const isNewUser = (user: User): boolean =>
  differenceInMinutes(new Date(), user.createdAt) < ELIGIBILITY_MINUTES;

export const checkUserAlreadyHasCoupon = async ({
  userId,
  couponCode,
}: {
  userId: string;
  couponCode: string;
}): Promise<boolean> => {
  try {
    const userRef = doc(db, 'User', userId);
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      throw new Error(`User with ID ${userId} does not exist.`);
    }
    const userCoupons = (userDoc.data() as UserData).coupons;
    return userCoupons.some((coupon) => coupon.code === couponCode);
  } catch (error) {
    console.error('Error checking user coupon:', error);
    return false;
  }
};

export const overrideCouponExpirationDate = async ({
  userId,
  couponCode,
  expirationDate,
}: {
  userId: User['uid'];
  couponCode: CouponCodeData['code'];
  expirationDate: Date;
}) => {
  const userRef = doc(db, 'User', userId);
  const userDoc = await getDoc(userRef);
  const userData = userDoc.data() as UserData;

  const updatedCoupons = userData.coupons.filter((c) => c.code !== couponCode);

  const overridedCoupon = userData.coupons.find((c) => c.code === couponCode);
  if (overridedCoupon) {
    overridedCoupon.expirationDate = expirationDate;
  }

  await updateDoc(userRef, {
    coupons: [...updatedCoupons, overridedCoupon],
  });
};

const showCouponIssuedMessage = async (overlay: ReturnType<typeof useOverlay>): Promise<void> => {
  return new Promise((resolve) => {
    overlay.open(({ isOpen, close }) => (
      <BlogReaderSignUpEventResultModal
        open={isOpen}
        onOpenChange={() => {
          close();
          resolve();
        }}
      />
    ));
  });
};

export const BlogExploreCouponEvent: FC<{
  modalOpenState: boolean;
  setModalOpenState: (open: boolean) => void;
}> = ({ modalOpenState, setModalOpenState }) => {
  const toast = useToast();

  const { isUserExist, user } = useGetUser();

  const [isEventProcessed, setEventProcessed] = useState<boolean | null>(null);
  const [isCouponIssued, setIsCouponIssued] = useState<boolean>(false);

  const [notificationState, setNotificationState] = useAtom(notificationStateAtom);

  const marketingAgreeState = useMemo(() => Boolean(user?.notificationAgreed), [user]);

  const isMarketingAgreementRequired = useMemo(() => {
    return marketingAgreeState && notificationState === 'PermissionStatus.granted';
  }, [marketingAgreeState, notificationState]);

  const router = useRouter();

  const issueBlogReaderCoupon = async () => {
    if (!user) return;

    try {
      // if (isEventProcessed) return;

      const userAlreadyHasCoupon = await checkUserAlreadyHasCoupon({
        userId: user.uid,
        couponCode: BLOG_READER_COUPON_CODE,
      });

      if (userAlreadyHasCoupon) {
        toast.openToast({
          title: '이미 발급 받은 쿠폰입니다.',
        });

        return;
      }

      await user.addCoupon({ code: BLOG_READER_COUPON_CODE });
      await overrideCouponExpirationDate({
        userId: user.uid,
        couponCode: BLOG_READER_COUPON_CODE,
        expirationDate: addDays(new Date(), COUPON_EXPIRATION_DAYS),
      });
      setModalOpenState(false);
      // await showCouponIssuedMessage(overlay);
      await router.push('/explore');
      toast.openToast({ title: '5,000원 할인 쿠폰이 발급되었습니다.', yPosition: 160 });
      setIsCouponIssued(true);
    } catch (error) {
      console.error('쿠폰 발급 도중 오류가 발생했습니다.:', error);
      toast.openToast({
        title: '쿠폰 발급 도중 오류가 발생했습니다.',
      });
    }
  };

  useEffect(() => {
    const isEventProcessed =
      window.localStorage.getItem(BLOG_POST_EVENT_LOCAL_STORAGE_KEY) === 'true';
    setEventProcessed(isEventProcessed);
  }, []);

  const handleOnClickIssueCoupon = () => {
    if (window) {
      window.localStorage.setItem(BLOG_POST_EVENT_LOCAL_STORAGE_KEY, 'true');
    }
    const checkAndIssueCoupon = async () => {
      await issueBlogReaderCoupon();
    };

    console.log(
      'isEventProcessed',
      isEventProcessed,
      isUserExist,
      isMarketingAgreementRequired,
      !isCouponIssued,
    );
    if (isEventProcessed && isUserExist && isMarketingAgreementRequired && !isCouponIssued) {
      checkAndIssueCoupon();
    }
  };

  return (
    <>
      <BlogExploreCouponModal
        open={modalOpenState}
        onOpenChange={setModalOpenState}
        onSubmit={handleOnClickIssueCoupon}
      ></BlogExploreCouponModal>
    </>
  );
};

const BlogExploreCouponModal: FC<{
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmit?: () => void;
}> = ({ open, onOpenChange, onSubmit }) => {
  return (
    <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
      <RadixDialog.Portal> </RadixDialog.Portal>
      <RadixDialog.Overlay className="bg-new-black-25 data-[state=open]:animate-overlayShow fixed inset-0 z-[200]" />
      <RadixDialog.Content className="bg-new-white fixed left-1/2 top-1/2 z-[200] flex w-[320px] -translate-x-1/2 -translate-y-1/2 flex-col items-center rounded-[24px] px-16 pb-24 pt-32 text-center">
        <div
          id="posthog-blog-reader-signup-event-result"
          className="bg-new-white absolute left-1/2 top-1/2 flex w-[320px] -translate-x-1/2 -translate-y-1/2 flex-col items-center rounded-[24px] px-16 pb-24 pt-32 text-center"
        >
          <button
            type="button"
            className="absolute right-16 top-24"
            onClick={() => {
              onOpenChange(false);
              window.localStorage.setItem(BLOG_POST_EVENT_LOCAL_STORAGE_KEY, 'false');
            }}
          >
            <NewIcon icon="x-24" />
          </button>
          <Image src="/events/blog/coupon.png" alt="쿠폰 아이콘" width={72} height={72} priority />
          <h1 className="text-new-Sub-Title  py-8">할인 쿠폰이 발급되었습니다!</h1>
          <p className="text-new-Body2-medium text-new-gray-500 break-keep pb-24">
            운전면허학원 5,000원 할인 쿠폰이 발급되었습니다. 지금 바로 사용해보세요!
          </p>
          <MarketingAgreementBottomSheet
            onSubmit={() => console.log('marketing Submit')}
            option={BLOG_COUPON_MARKETING_OPTION}
          >
            <LoginBottomSheet>
              <BoxButton
                color={BOXBUTTON_COLOR.DTYellow}
                text={'할인 쿠폰 받기'}
                onClick={onSubmit}
              ></BoxButton>
            </LoginBottomSheet>
          </MarketingAgreementBottomSheet>
        </div>
      </RadixDialog.Content>
    </RadixDialog.Root>
  );
};

const LoginBottomSheet = ({
  children,
  className,
  redirectUrl,
}: {
  children: React.ReactNode;
  className?: string;
  redirectUrl?: string;
}) => {
  const { user, isLoading } = useGetUser();

  if (user || isLoading) return children;
  return (
    <NewBottomSheet>
      <NewBottomSheetTrigger
        className={cn('w-full', className)}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </NewBottomSheetTrigger>
      <NewBottomSheetContent>
        <NewBottomSheetHeader>
          <NewBottomSheetTitle>로그인/회원가입하고 할인 쿠폰 받기</NewBottomSheetTitle>
          <NewBottomSheetDescription className="text-new-Body2-medium text-new-gray-500">
            쿠폰을 받으려면, 로그인/회원가입이 필요해요.
          </NewBottomSheetDescription>
        </NewBottomSheetHeader>
        <div className="flex w-full items-center justify-center">
          <Image src="/need-login.png" width={96} height={96} alt="로그인 필요" />
        </div>
        <Spacer className="h-[24px]" />
        <KakaoLoginButton redirectUrl={redirectUrl} />
      </NewBottomSheetContent>
    </NewBottomSheet>
  );
};
