import { Discount } from '../discount';

const isDiscountPaidByDT = (discount: Discount) => {
  return discount.isDiscountPaidByAcademy !== true && discount.discountSource === 'dt';
};

export const getDiscountPrice = ({
  price,
  discounts,
  discount,
}: {
  price: number;
  discounts?: Discount[];
  discount?: Discount;
}): DiscountReturn => {
  if (discount) {
    return {
      원금: price,
      할인금: discount.convertToPrice(price),
      최종금: price - discount.convertToPrice(price),
      운전선생할인금: discount.convertToPrice(price),
      외부할인금: discount.convertToPrice(price),
    };
  } else if (discounts) {
    // 우선순위 적용: 외부 쿠폰 > 내부 쿠폰, percent 존재 > percent 미존재
    discounts.sort((a, b) => {
      // 외부와 내부 쿠폰 우선순위 비교
      if (a.discountSource === 'simulation' && b.discountSource === 'dt') return -1;
      if (a.discountSource === 'dt' && b.discountSource === 'simulation') return 1;

      // 같은 source 내에서 percent 존재 여부 비교
      if (a.discountPercent !== undefined && b.discountPercent === undefined) return -1;
      if (a.discountPercent === undefined && b.discountPercent !== undefined) return 1;

      return 0; // 나머지 경우는 순서 변경 없음
    });

    const totalDiscountPrice = discounts.reduce((acc, discount) => {
      return acc + discount.convertToPrice(price);
    }, 0);

    const dtDiscountPrice = discounts.reduce((acc, discount) => {
      if (isDiscountPaidByDT(discount)) {
        return acc + discount.convertToPrice(price);
      } else {
        return acc;
      }
    }, 0);

    const notDTDiscountPrice = discounts.reduce((acc, discount) => {
      if (!isDiscountPaidByDT(discount)) {
        return acc + discount.convertToPrice(price);
      } else {
        return acc;
      }
    }, 0);

    return {
      원금: price,
      할인금: totalDiscountPrice,
      최종금: price - totalDiscountPrice,
      운전선생할인금: dtDiscountPrice,
      외부할인금: notDTDiscountPrice,
    };
  } else {
    return {
      원금: price,
      할인금: 0,
      최종금: price,
      운전선생할인금: 0,
      외부할인금: 0,
    };
  }
};
