import { useGetSalesAcademy } from '@db/collections/SalesAcademy';
import { QUERY_KEY } from '@db/constants';
import { useQueryWithClient } from '@db/hooks';

import { getAcademy } from '../../Academy';
import { useGetUser } from './use-get-user';

export const useGetUserConnectedAcademy = () => {
  const getUserResult = useGetUser();

  const { user } = getUserResult;

  const result = useQueryWithClient({
    queryKey: [QUERY_KEY.ACADEMY, user?.academyUid ?? ''],
    queryFn: getAcademy,
    enabled: user && !!user.academyUid,
  });

  const salesAcademyResult = useGetSalesAcademy(user?.academyUid);

  return {
    ...result,
    ...getUserResult,
    academy: result.data,
    refetchAcademy: result.refetch,
    salesAcademy: salesAcademyResult.data,
    refetchSalesAcademy: salesAcademyResult.refetch,
    academyId: user?.academyUid ?? '',
  };
};
