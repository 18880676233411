import { Academy } from '@db/collections/Academy';
import { updateEventParticipantCount } from '@db/collections/Config';
import { getCouponCodeData } from '@db/collections/CouponCode';
import { QUERY_KEY } from '@db/constants';
import { QueryModel } from '@db/query-models';
import { getDataWithReactQuery } from '@db/utils';
import { auth, db } from 'firebase-config';
import { signOut } from 'firebase/auth';
import {
  DocumentReference,
  arrayUnion,
  collection,
  doc,
  documentId,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { chunkArray, findMethodAndReplace } from 'shared-values';
import { v4 as uuidv4 } from 'uuid';

import { getEventRoom } from '../../EventRoom';
import { Order } from '../../Order';
import { getRandomRouletteReward, isUserManager } from '../utils';
import { Bookmark } from './bookmark';
import { IssuedCoupon } from './issued-coupon';
import { UserVerification } from './user-verification';

const RECOMMEND_MAX_NUM = 5;

export const publishCoupon = (coupon: CouponCodeData): IssuedCouponData => {
  return {
    ...coupon,
    id: uuidv4(),
    isUsed: false,
    isRequested: false,
    issuedDate: new Date(),
  };
};

export class User extends QueryModel {
  uid: string;
  togetherEvent: {
    academy: DocumentReference;
    eventRoom: DocumentReference;
  } | null;
  academyUid?: string;
  email: string;
  name: string;
  realName?: string;
  address?: string;
  detailAddress?: string;
  phoneNum: string;
  provider: Provider;
  orders: Order[];
  token?: string;
  verification?: UserVerification | null;
  birthday?: string;
  code: string;
  friendsCode: friendsCode[];
  isVerifiedByAcademy: boolean;
  couponChances: number;
  coupons: IssuedCoupon[];
  device?: Device;
  bookmarks: Bookmark[];
  createdAt: Date;
  notificationAgreed: boolean;
  rouletteTickets: DocumentReference[];
  alreadyGetKakaoGift?: boolean;
  birthYear: string;
  newbieEvent?: boolean;
  newbieEventTime?: Date;
  isTrainingApplied?: boolean;
  assignedHighschoolId?: string;
  assignedHighschoolName?: string;
  highschoolVotesContributed?: number;
  highschoolVotesLeft?: number;
  go3ChickenMarketingAgreed?: boolean;
  togetherEventMarketingAgreed?: boolean;

  constructor({
    uid,
    togetherEvent,
    academyUid,
    email,
    name,
    realName,
    address,
    detailAddress,
    phoneNum,
    provider,
    orders = [],
    token,
    verification,
    birthday,
    code,
    friendsCode,
    isVerifiedByAcademy,
    couponChances,
    coupons = [],
    device,
    bookmarks = [],
    createdAt,
    queryClient,
    queryKey,
    notificationAgreed = false,
    alreadyGetKakaoGift,
    rouletteTickets,
    birthYear,
    newbieEvent,
    newbieEventTime,
    isTrainingApplied,
    assignedHighschoolId,
    assignedHighschoolName,
    highschoolVotesContributed,
    highschoolVotesLeft,
    go3ChickenMarketingAgreed,
    togetherEventMarketingAgreed,
  }: DataModel<UserData>) {
    super({ queryClient, queryKey, instanceConstructor: User, className: 'User' });

    this.uid = uid;
    this.togetherEvent = togetherEvent;
    this.academyUid = academyUid;
    this.email = email;
    this.name = name;
    this.realName = realName;
    this.address = address;
    this.detailAddress = detailAddress;
    this.phoneNum = phoneNum;
    this.provider = provider;
    this.orders = orders.map((order) => new Order(order));
    this.token = token;
    this.verification = verification;
    this.birthday = birthday;
    this.code = code;
    this.friendsCode = friendsCode;
    this.isVerifiedByAcademy = isVerifiedByAcademy;
    this.couponChances = couponChances;
    this.coupons = coupons.map((coupon) => new IssuedCoupon(coupon));
    this.device = device;
    this.bookmarks = bookmarks.map((bookmark) => new Bookmark(bookmark));
    this.createdAt = createdAt;
    this.notificationAgreed = notificationAgreed;
    this.alreadyGetKakaoGift = alreadyGetKakaoGift;
    this.rouletteTickets = rouletteTickets ?? [];
    this.birthYear = birthYear ?? '';
    this.newbieEvent = newbieEvent;
    this.newbieEventTime = newbieEventTime;
    this.isTrainingApplied = isTrainingApplied;
    this.assignedHighschoolId = assignedHighschoolId;
    this.assignedHighschoolName = assignedHighschoolName;
    this.highschoolVotesContributed = highschoolVotesContributed;
    this.highschoolVotesLeft = highschoolVotesLeft;
    this.go3ChickenMarketingAgreed = go3ChickenMarketingAgreed ?? false;
    this.togetherEventMarketingAgreed = togetherEventMarketingAgreed ?? false;
  }

  get isVerified() {
    return this.verification?.isVerified ?? false;
  }

  get isAlreadyParticipatedTogetherEvent() {
    return this.togetherEvent !== null;
  }

  get isManager() {
    return isUserManager(this.uid);
  }

  get isAlreadyReserved() {
    if (
      process.env.NEXT_PUBLIC_PLATFORM_ENV !== 'production' ||
      this.isManager ||
      this.uid === 'kakao:3282791618'
    )
      return false;

    if (this.orders.length > 0) {
      const userLastOrder = this.orders[this.orders.length - 1];
      if (userLastOrder.state === 'Appointed' || userLastOrder.state === 'Request') {
        return true;
      }
    }
    return false;
  }

  get isAlreadyGetKakaoGift() {
    return this.alreadyGetKakaoGift ?? false;
  }

  get hasEventRoom() {
    return this.togetherEvent !== null;
  }

  get eventRoomId() {
    if (!this.togetherEvent) return '';
    return this.togetherEvent.eventRoom.id;
  }
  get eventAcademyId() {
    if (!this.togetherEvent) return '';
    return this.togetherEvent.academy.id;
  }

  get canWriteReview() {
    return this.orders.some((order) => order.canWriteReview);
  }

  private get ref() {
    return doc(db, this.provider === 'non-member' ? 'NonMemberUser' : 'User', this.uid);
  }

  public isAlreadyParticipatedCouponEvent(source: string) {
    return this.coupons.some((coupon) => coupon.source === source);
  }

  public async addCoupon({
    code = '',
    coupon,
    couponCode,
    academyId,
    schoolName,
    payload,
    expirationDate,
  }: {
    code?: string;
    coupon?: IssuedCouponData;
    couponCode?: CouponCodeData;
    academyId?: string;
    schoolName?: string;
    // 쿠폰에 자유롭게 저장하는 데이터
    payload?: any;
    // 쿠폰의 유효기간을 override하고 싶을 때
    expirationDate?: Date;
  }) {
    let resultCoupon: IssuedCouponData | null = null;
    // 1. 쿠폰을 직접 넣는 경우 (데이터가 온전히 다 존재하는 경우)
    if (coupon) {
      resultCoupon = coupon;
    }
    // 2. 서버 쿠폰 코드 데이터를 넣는 경우 (데이터가 일부만 존재하는 경우)
    else if (couponCode) {
      resultCoupon = publishCoupon(couponCode);
    }
    // 3. 코드를 통해 입력하는 경우 (id 값만 있는 경우)
    else if (code.length !== 0) {
      code = code.toUpperCase();
      const coupon = await getCouponCodeData(code);

      resultCoupon = publishCoupon(coupon);
    }

    if (!resultCoupon) {
      throw new Error('쿠폰을 찾을 수 없습니다.');
    }

    const couponName = coupon ? coupon.name : couponCode ? couponCode.name : '';

    // 학교 이벤트일 경우 이름을 override
    resultCoupon = {
      ...resultCoupon,
      ...(schoolName
        ? {
            schoolName: schoolName,
            name: couponName.includes('고등학교')
              ? couponName.replace('고등학교', schoolName)
              : couponName.includes('대학교')
              ? couponName.replace('대학교', schoolName)
              : couponName,
          }
        : {}),
      ...(code.length !== 0 ? { inputCode: code } : {}),
      ...(payload ? { payload } : {}),
      ...(expirationDate ? { expirationDate } : {}),
      ...(resultCoupon.daysToBeExpired
        ? {
            expirationDate: new Date(
              new Date(
                new Date().getTime() + resultCoupon.daysToBeExpired * 24 * 60 * 60 * 1000,
              ).setHours(23, 59, 59, 999),
            ),
          }
        : {}),
    };

    // 이미 쿠폰을 가지고 있을 경우 가진 쿠폰을 대체
    if (this.isAlreadyParticipatedCouponEvent(resultCoupon.source)) {
      this.coupons = this.coupons.map((coupon) =>
        coupon.id === resultCoupon.id ? new IssuedCoupon(resultCoupon) : coupon,
      );

      await updateDoc(this.ref, {
        coupons: this.coupons.map((coupon) => coupon.get()),
      });
    } else {
      await updateDoc(this.ref, {
        coupons: arrayUnion(resultCoupon),
      });
      this.coupons = [...this.coupons, new IssuedCoupon(resultCoupon)];
    }

    // react-query로 선언된 client query일 경우
    if (this._queryClient && this._queryKey) {
      // client update
      this._setData({
        coupons: this.coupons,
      });
    }

    return resultCoupon;
  }

  public isBookMarked(academyId: string) {
    return this.bookmarks.find((v) => v.id === academyId);
  }

  public async createRoom(academy: Academy) {
    const now = new Date();
    const timeLimit = new Date(now.getTime() + 1000 * 60 * 60 * 24 * 2);

    const { id, name, images, paymentType, togetherEventTable, type, locationAttachedName } =
      academy;

    const roomId = uuidv4();

    const eventRoomData = {
      eventAcademy: {
        ref: doc(db, 'Academy', id),
        name: name,
        image: images[0] ?? '',
        paymentType,
        type: type,
        locationAttachedName: locationAttachedName ?? '',
        ...(togetherEventTable ? { togetherEventTable: togetherEventTable.get() } : {}),
      },
      discount: {
        discountPrice: 0,
        discountPercent: 0,
        discountType: 'together',
        discountSource: togetherEventTable ? 'simulation' : 'dt',
        lessonConditions: [],
        academyId: '',
        typeId: roomId,
        // typeId : uuid로 생성
        // ex : 고수의운전면허 검단사거리역점 10% 할인, 연습장 동시등록 이벤트 할인
        promotionName: `운전선생 ${togetherEventTable ? '동시등록' : '같이취득'} 이벤트 할인`,
      },
      member: [
        {
          id: this.uid,
          isLeader: true,
          name: this.name,
          selectedTimes: [],
          lessonInfo: null,
          selectedRange: null,
          state: 'selecting',
          isPaid: false,
          isAlerted: false,
          joinedAt: now,
        },
      ],
      timeLimit: timeLimit,
    } as Omit<EventRoomData, 'id'>;

    await setDoc(doc(db, 'EventRoom', roomId), eventRoomData);

    const newData = {
      togetherEvent: {
        academy: doc(db, 'Academy', academy.id),
        eventRoom: doc(db, 'EventRoom', roomId),
      },
    };

    await updateDoc(this.ref, {
      ...newData,
    });

    updateEventParticipantCount('eventRoom');

    this._setData({
      ...newData,
    });

    return roomId;
  }

  public async getEventRoom() {
    const { togetherEvent } = this;
    if (!togetherEvent) return null;

    const queryKey = [QUERY_KEY.EVENT_ROOM, togetherEvent.eventRoom.id];

    return await getDataWithReactQuery({
      queryKey,
      queryFn: getEventRoom,
      queryClient: this._queryClient,
    });
  }

  public async exitRoom() {
    const eventRoom = await this.getEventRoom();
    if (!eventRoom) return;
    await eventRoom.deleteUser(this);
    this._setData({
      togetherEvent: null,
    });
  }

  public async changeInfo(updateData: object) {
    await updateDoc(this.ref, updateData);
    this._setData(updateData);
  }

  public async logOut() {
    if (!this._queryClient) throw new Error('queryClient가 없습니다.');
    await signOut(auth);
    await this._queryClient.resetQueries({ queryKey: this._queryKey });
  }

  // agreed가 없으면 toggle
  public async setNotificationAgreed(agreed?: boolean) {
    await updateDoc(this.ref, {
      notificationAgreed: agreed ?? !this.notificationAgreed,
    });

    this._setData({
      notificationAgreed: agreed ?? !this.notificationAgreed,
    });
  }

  public async getTickets() {
    const ticketIds = this.rouletteTickets.map((ticket) => ticket.id);
    const chunks = chunkArray(ticketIds, 10);

    let result: RouletteTicket[] = [];
    // ref들을 one-round-trip에 가져오기
    // https://stackoverflow.com/questions/46721517/google-firestore-how-to-get-several-documents-by-multiple-ids-in-one-round-tri
    // 순서가 중요한가 ? 생각해보기
    for await (const chunk of chunks) {
      const q = query(collection(db, 'RouletteTicket'), where(documentId(), 'in', chunk));
      const querySnapshot = await getDocs(q);
      const newArray: RouletteTicket[] = [];
      querySnapshot.forEach((docQuery) => {
        const docData = { ...docQuery.data(), id: docQuery.id } as RouletteTicket;
        findMethodAndReplace(docData, 'toDate');
        newArray.push(docData);
      });

      result = [...result, ...newArray];
    }

    return result;
  }

  public async getValidTickets(boardId: RouletteBoardId) {
    return (await this.getTickets())
      .filter((ticket) => ticket.isUsed === false)
      .filter((ticket) => ticket.boardId === boardId);
  }

  public async playRoulette(boardId: RouletteBoardId, rouletteRewards: RouletteReward[]) {
    const determinedReward = getRandomRouletteReward(rouletteRewards);

    const validTickets = await this.getValidTickets(boardId);

    if (validTickets.length === 0) throw new Error('유효한 티켓이 없습니다.');

    const randomExternalKey = uuidv4();

    // RouletteResult에 생성
    const docId = validTickets[0].id;

    const rouletteResult = {
      ...validTickets[0],
      isUsed: true,
      determinedReward: { ...determinedReward, randomExternalKey },
      candidates: rouletteRewards,
      usedAt: new Date(),
    } as RouletteTicket;

    // id는 RouletteResult DB에서 필요 없으니 제거
    delete (rouletteResult as Partial<RouletteTicket>).id;

    await updateDoc(doc(db, 'RouletteTicket', docId), { ...rouletteResult });
    // page route

    return { docId, determinedReward: { ...determinedReward, randomExternalKey } };
  }

  public getApplicableCoupons(discountCondition: DiscountCondition) {
    return this.coupons.filter(
      (coupon) => coupon.discount.canApply(discountCondition) && !coupon.isUsed,
    );
  }

  public async updateAddress({
    address,
    detailAddress,
  }: {
    address: string;
    detailAddress: string;
  }) {
    await updateDoc(this.ref, {
      address,
      detailAddress,
    });

    this._setData({
      address,
      detailAddress,
    });
  }

  public async updateNewbieEvent() {
    await updateDoc(this.ref, {
      newbieEvent: true,
      newbieEventTime: new Date(),
    });

    // updateEventParticipantCount('newbieEvent');

    this._setData({
      newbieEvent: true,
    });
  }

  public async updateTrainingApplied() {
    await updateDoc(this.ref, {
      isTrainingApplied: true,
    });

    this._setData({
      isTrainingApplied: true,
    });
  }
}
