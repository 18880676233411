import { useEffect, useState } from 'react';

import { useGetUser } from '@db/collections';
import { NewIcon } from '@ds/components/new/NewIcon';
import { Spacer } from '@ds/components/reusable/Spacer';
import * as Dialog from '@radix-ui/react-dialog';
import { addDays } from 'date-fns';
import { NewBoxButton, useToast } from 'design-system';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useLocalStorage } from 'usehooks-ts';

import { checkUserAlreadyHasCoupon } from '@components/Activation/BlogExploreCouponEvent';
import { MarketingAgreementBottomSheet } from '@components/MarketingAgreementBottomSheet';
import { NeedLoginBottomSheet } from '@components/NeedLoginBottomSheet/NeedLoginBottomSheet';

export const CARDOC_COUPON_CODE = 'CARDOCEVENT';
export const COUPON_EXPIRATION_DAYS = 60;
const CardocCouponModal = ({ close }: { close: () => void }) => {
  const { user } = useGetUser();
  const { openToast } = useToast();
  const router = useRouter();
  const [, setUnreceivedCoupon] = useLocalStorage('last-unreceived-coupon-code', '');
  const [couponIssued, setCouponIssued] = useState(false);
  useEffect(() => {
    if (!user) return;

    const checkCoupon = async () => {
      const couponIssuedState = await checkUserAlreadyHasCoupon({
        userId: user.uid,
        couponCode: CARDOC_COUPON_CODE,
      });
      setCouponIssued(couponIssuedState);
    };

    checkCoupon();
  }, []);

  return (
    <Dialog.Root open>
      <Dialog.Portal
        forceMount
        container={typeof document !== 'undefined' ? document.getElementById('portal') : undefined}
      >
        <Dialog.Overlay className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0  bg-new-black-50 fixed inset-0 z-[900]" />
        <Dialog.Content className=" data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] fixed left-[50%] top-[50%] z-[1000] flex w-[312px] translate-x-[-50%] translate-y-[-50%] flex-col items-center rounded-[24px] border bg-white duration-200">
          <Dialog.Close
            className="absolute right-[16px] top-[16px] z-[1100]"
            onClick={() => {
              if (!couponIssued) {
                setUnreceivedCoupon(CARDOC_COUPON_CODE);
              }
              close();
            }}
          >
            <NewIcon icon="x-outlined" width={24} height={24} className="fill-new-gray-900" />
          </Dialog.Close>
          <Spacer className="h-32" />
          <Image
            className="h-[107px] w-[152px]"
            src="/events/cardoc/cardoc-coupon-image.png"
            alt="할인 쿠폰"
            width={456}
            height={323}
          />
          <div className="flex w-full flex-col items-center px-16">
            <Spacer className="h-24" />
            <p className="text-new-Sub-Title">운전학원 할인 쿠폰 지급!</p>
            <Spacer className="h-8" />
            <p className="text-new-Body2-medium text-new-gray-500 text-center">
              30,000원 할인 쿠폰이 발급되었습니다.
              <br />
              지금 바로 받고 사용해보세요!
            </p>
            <Spacer className="h-24" />
            <MarketingAgreementBottomSheet
              onSubmit={async () => {
                if (!user) {
                  return;
                }

                const couponIssued = await checkUserAlreadyHasCoupon({
                  userId: user.uid,
                  couponCode: CARDOC_COUPON_CODE,
                });

                // LogEvent.유입.knockCouponAction('receive_coupon');

                if (couponIssued) {
                  openToast({
                    title: '이미 발급 받은 쿠폰입니다.',
                  });

                  return;
                }

                await user.addCoupon({
                  couponCode: {
                    code: CARDOC_COUPON_CODE,
                    id: CARDOC_COUPON_CODE,
                    source: CARDOC_COUPON_CODE,
                    name: '운전선생 X 카닥 3만원 할인 쿠폰',
                    expirationDate: addDays(new Date(), COUPON_EXPIRATION_DAYS),
                    canUse: true,
                    discount: {
                      academyId: '',
                      discountPrice: 30000,
                      discountSource: 'dt',
                      discountType: 'coupon',
                      promotionName: `운전선생 X 카닥 3만원 할인 쿠폰`,
                      lessonConditions: [],
                    },
                    schoolName: '',
                  },
                  expirationDate: addDays(new Date(), COUPON_EXPIRATION_DAYS),
                });

                await router.push('/profile/storage/coupon');
                openToast({
                  title: '30,000원 할인 쿠폰이 발급되었습니다.',
                });

                setUnreceivedCoupon('');

                close();
              }}
            >
              <NeedLoginBottomSheet>
                <NewBoxButton
                  label="할인 쿠폰 받기"
                  styles="filled-yellow"
                  fill
                  className="w-full"
                />
              </NeedLoginBottomSheet>
            </MarketingAgreementBottomSheet>
            <Spacer className="h-24" />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default CardocCouponModal;
