import { formatDate, subtractHHMM } from 'shared-values';

import { ConfirmedSelectedTime, Discount } from '../../../base-models';
import { QueryModel } from '../../../query-models';
import { Payment } from './payment';
import { PaymentStatus } from './payment-status';
import { Receipt } from './receipt';
import { VisitTrainingReceipt } from './visitTrainingReceipt';

export const convertStateToKorean = (state: OrderState) => {
  switch (state) {
    case 'Request':
      return '결제 완료';
    case 'Canceled':
      return '취소 완료';
    case 'Rejected':
      return '취소 완료';
    case 'Refunded':
      return '취소 완료';
    case 'RefundRequested':
      return '취소 대기';
    case 'Arrival':
      return '결제 완료';
    case 'Appointed':
      return '결제 완료';
    case 'NoShow':
      return '노쇼';
    default:
      return '';
  }
};

export class Order extends QueryModel {
  id: string;
  receipt: Receipt;
  payment: Payment;
  discountPayment?: Payment;
  paymentStatus: PaymentStatus;
  requestedAt: Date | null;
  canceledAt: Date | null;
  rejectedAt: Date | null;
  refundedAt: Date | null;
  appointedAt: Date | null;
  arrivalAt: Date | null;
  noShowAt: Date | null;
  deletedAt: Date | null;
  refundPercent?: '100%' | '50%' | '70%' | '0%';
  state: OrderState;
  arrivalMethod: ArrivalMethod;
  beforeRefundState?: OrderState;
  notifyRecords: NotifyRecords;
  selectedTime?: ConfirmedSelectedTime;
  isKeyInPurchase: boolean;
  isSimulation: boolean;
  discounts: Discount[];
  reviewState: OrderData['reviewState'];
  visitTrainingReceipt?: VisitTrainingReceipt;
  note?: string;
  constructor({
    id,
    receipt,
    payment,
    discountPayment,
    paymentStatus,
    requestedAt,
    canceledAt,
    rejectedAt,
    refundedAt,
    appointedAt,
    arrivalAt,
    noShowAt,
    deletedAt,
    refundPercent,
    state,
    arrivalMethod,
    beforeRefundState,
    notifyRecords,
    selectedTime,
    queryClient,
    queryKey,
    isKeyInPurchase,
    isSimulation,
    discounts,
    reviewState,
    visitTrainingReceipt,
    note,
  }: DataModel<OrderData>) {
    super({ queryClient, queryKey, instanceConstructor: Order, className: 'Order' });
    this.id = id;
    this.receipt = new Receipt(receipt);
    this.payment = new Payment(payment);
    this.discountPayment = discountPayment ? new Payment(discountPayment) : undefined;
    this.paymentStatus = new PaymentStatus(paymentStatus);
    this.requestedAt = requestedAt;
    this.canceledAt = canceledAt;
    this.rejectedAt = rejectedAt;
    this.refundedAt = refundedAt;
    this.appointedAt = appointedAt;
    this.arrivalAt = arrivalAt;
    this.noShowAt = noShowAt;
    this.deletedAt = deletedAt;
    this.refundPercent = refundPercent;
    this.state = state;
    this.arrivalMethod = arrivalMethod;
    this.beforeRefundState = beforeRefundState;
    this.notifyRecords = notifyRecords;
    this.selectedTime = selectedTime ? new ConfirmedSelectedTime(selectedTime) : undefined;
    this.isKeyInPurchase = isKeyInPurchase ?? false;
    this.isSimulation = isSimulation ?? false;
    this.discounts = discounts ? discounts.map((discount) => new Discount(discount)) : [];
    this.reviewState = reviewState;
    this.visitTrainingReceipt = visitTrainingReceipt
      ? new VisitTrainingReceipt(visitTrainingReceipt)
      : undefined;
    this.note = note ?? '';
  }

  get koreanState() {
    return convertStateToKorean(this.state);
  }

  get startTime() {
    return this.selectedTime?.firstVisit.startTime || this.arrivalAt;
  }

  get isDiscounted() {
    return (
      this.discounts.length > 0 &&
      this.discounts.some(
        (discount) => Number(discount.discountPrice) > 0 || Number(discount.discountPercent) > 0,
      )
    );
  }

  get canWriteReview() {
    return this.reviewState === 'can-write';
  }

  get canRequestRefund() {
    return this.state === 'Request' || this.state === 'Appointed';
  }

  /**
   * order에 들어있는 예약일시 정보에 따른 일시 출력 메서드(24.02.08 - 김형겸)
   * recept내의 필드가 두 개 들어가지만, order 바로 하위에 있는 selectedTime을 고려해 Order 클래스 내에 생성함.
   * 추후 DB구조 개편에 따라 변경될 수 있음.
   */
  get reservationDateTime() {
    const {
      selectedTime,
      receipt: { selectedTimes = [], selectedRange },
    } = this;

    if (selectedRange) {
      const { startDate, endDate } = selectedRange;

      const formattedStartDate = formatDate(startDate, 'YY.MM.DD');
      const formattedEndDate = formatDate(endDate, 'YY.MM.DD');

      const isSingleDay = formattedStartDate === formattedEndDate;

      return `${formattedStartDate} ${!isSingleDay ? `~ ${formattedEndDate}` : ''}`;
    }

    if (selectedTime) {
      const { times, firstVisit, id, fullString } = selectedTime;

      if (id !== 'custom') {
        return fullString;
      }
      if (times.length === 0) {
        return firstVisit.fullString;
      }
      return times.map((dateTime) => dateTime.fullString).join(' ~ ');
    }

    return selectedTimes.map((time) => {
      const { date, startTime, endTime } = time;
      return `${formatDate(date, 'YY.MM.DD')} ${subtractHHMM(startTime, 10)} ~ ${endTime}`;
    });
  }
}
