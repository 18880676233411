import { type FC, useEffect, useReducer } from 'react';

import * as RadixDialog from '@radix-ui/react-dialog';
import { BOXBUTTON_COLOR, BoxButton, NewIcon } from 'design-system';
import { motion, useScroll } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { scrollContainerRef } from '@components/Layout';

type Actions = '스크롤완료' | '닫음';

type State = {
  modal: '닫힘' | '열림';
  reason: '진입' | '스크롤로열림' | '유저가닫음';
};

const initialState = {
  modal: '닫힘',
  reason: '진입',
} satisfies State;

const reducer = (s: State, a: Actions): State => {
  switch (a) {
    case '스크롤완료':
      if (s.reason === '유저가닫음') {
        return {
          modal: '닫힘',
          reason: '유저가닫음',
        };
      }
      return {
        modal: '열림',
        reason: '스크롤로열림',
      };
    case '닫음':
      return {
        modal: '닫힘',
        reason: '유저가닫음',
      };
  }
};

export const BlogReaderSignUpEventCTAModal: FC = () => {
  const router = useRouter();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { scrollYProgress } = useScroll({
    container: scrollContainerRef,
    layoutEffect: false,
  });

  useEffect(() => {
    const unsub = scrollYProgress.on('change', (latestValue) => {
      if (latestValue > 0.9 && state.reason === '진입') {
        dispatch('스크롤완료');
      }
    });

    return () => {
      unsub();
    };
  }, [scrollYProgress, state]);

  const handleSignUpClick = () => {
    sessionStorage.setItem('is-blog-event-target', 'true');
    router.push(`/login?redirectUrl=${router.asPath}`);
  };

  return (
    <RadixDialog.Root
      open={state.modal === '열림'}
      onOpenChange={() => {
        dispatch('닫음');
      }}
    >
      <RadixDialog.Portal container={document.getElementById('portal')}>
        <RadixDialog.Overlay className="bg-new-black-25 fixed inset-0 z-[200]" />
        <RadixDialog.Content className="bg-new-white fixed left-1/2 top-1/2 z-[200] flex w-[320px] -translate-x-1/2 -translate-y-1/2 flex-col items-center rounded-[24px] px-16 pb-24 pt-32 text-center">
          <motion.div
            id="posthog-blog-reader-signup-event-cta"
            className="flex flex-col items-center"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{ duration: 0.3, type: 'spring', damping: 30, stiffness: 400 }}
          >
            <button
              type="button"
              className="absolute right-16 top-24"
              onClick={() => dispatch('닫음')}
            >
              <NewIcon icon="x-24" />
            </button>
            <Image src="/image-present.png" alt="선물 아이콘" width={72} height={72} priority />
            <h1 className="text-new-Sub-Title py-8">운전학원 할인 쿠폰 증정! </h1>
            <p className="text-new-Body2-medium text-new-gray-500 pb-24">
              지금 운전선생에 가입하시면,
              <br />
              운전면허학원 할인 쿠폰을 드립니다.
              <br />
              (내정보 {'>'} 쿠폰함에서 쿠폰 확인 가능합니다.)
            </p>
            <BoxButton
              color={BOXBUTTON_COLOR.DTYellow}
              text="운전선생 가입하기"
              onClick={handleSignUpClick}
            />
          </motion.div>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export const BlogReaderSignUpEventResultModal: FC<{
  open: boolean;
  onOpenChange: (open?: boolean) => void;
}> = ({ open, onOpenChange }) => {
  return (
    <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
      <RadixDialog.Portal container={document.getElementById('portal')}>
        <RadixDialog.Overlay className="bg-new-black-25 data-[state=open]:animate-overlayShow fixed inset-0 z-[200]" />
        <RadixDialog.Content className="bg-new-white fixed left-1/2 top-1/2 z-[200] flex w-[320px] -translate-x-1/2 -translate-y-1/2 flex-col items-center rounded-[24px] px-16 pb-24 pt-32 text-center">
          <div
            id="posthog-blog-reader-signup-event-result"
            className="bg-new-white absolute left-1/2 top-1/2 flex w-[320px] -translate-x-1/2 -translate-y-1/2 flex-col items-center rounded-[24px] px-16 pb-24 pt-32 text-center"
          >
            <button
              type="button"
              className="absolute right-16 top-24"
              onClick={() => onOpenChange(false)}
            >
              <NewIcon icon="x-24" />
            </button>
            <Image
              src="/events/blog/coupon.png"
              alt="쿠폰 아이콘"
              width={72}
              height={72}
              priority
            />
            <h1 className="text-new-Sub-Title py-8">할인 쿠폰이 발급되었습니다!</h1>
            <p className="text-new-Body2-medium text-new-gray-500 break-keep pb-24">
              운전면허학원 5,000원 할인 쿠폰이 발급되었습니다. 지금 바로 사용해보세요!
            </p>
            <Link
              id="posthog-blog-post-signup-coupon-issues-cta"
              href="/explore"
              className="w-full"
            >
              <BoxButton color={BOXBUTTON_COLOR.DTYellow} text="운전학원 예약하러 가기" />
            </Link>
          </div>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};
