'use client';

import * as React from 'react';

import { type VariantProps, cva } from 'class-variance-authority';
import { cn } from 'tailwind-config';

const labelVariants = cva(
  'px-[6px] py-[2px] rounded-[4px] text-new-Caption2-bold inline-block whitespace-nowrap',
  {
    variants: {
      styles: {
        'filled-gray': 'text-new-gray-700 bg-new-gray-100',
        'filled-red': 'text-new-DTRed-400 bg-new-DTRed-100',
        'filled-purple': 'bg-new-DTPurple-100 text-new-DTPurple-500',
        'filled-green': 'bg-new-DTLime-100 text-new-DTGreen-500',
        'filled-yellow': 'bg-new-DTYellow-400 text-new-gray-900',
        'filled-blue': 'bg-new-DTSkyblue-100 text-new-DTSkyblue-600',
        'filled-orange': 'bg-new-DTOrange-100 text-new-DTOrange-400',
      },
    },
    defaultVariants: {
      styles: 'filled-gray',
    },
  },
);

interface LabelProps
  extends React.ButtonHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof labelVariants> {
  label: string;
}
const Label = React.forwardRef<HTMLDivElement, LabelProps>(
  ({ className, label, styles = 'filled-gray' }, ref) => {
    return (
      <p ref={ref} className={cn(labelVariants({ styles, className }))}>
        {label}
      </p>
    );
  },
);

export { Label, labelVariants };
