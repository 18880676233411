import React, { ComponentProps, HTMLAttributes, useState } from 'react';

import {
  NewBottomSheet,
  NewBottomSheetClose,
  NewBottomSheetContentInnerContent,
  NewBottomSheetOverlay,
  NewBottomSheetPortal,
  NewBottomSheetTrigger,
  NewBoxButton,
  NewIcon,
  Portal,
  Spacer,
  useOverlay,
} from 'design-system';
import Image from 'next/image';
import { cn } from 'tailwind-config';

import { Loading } from '@components';

import { isAppApproaching } from '@utils/Common';

import { useKakaoChannelAgree } from './hooks/use-kakao-channel-agree';
import { useMarketingAgree } from './hooks/use-marketing-agree';
import { useNotificationAgree } from './hooks/use-notification-agree';
import { useVerificationAgree } from './hooks/use-verification-agree';

type MarketingAgreementType = 'required' | 'optional' | 'not-needed';

export interface MarketingAgreementOption {
  marketingAgree: MarketingAgreementType;
  kakaoChannelAgree: MarketingAgreementType;
  verificationAgree: MarketingAgreementType;
  notificationAgree: MarketingAgreementType;
}

const DEFAULT_OPTION: MarketingAgreementOption = {
  marketingAgree: 'required',
  kakaoChannelAgree: !isAppApproaching() ? 'optional' : 'not-needed',
  notificationAgree: isAppApproaching() ? 'required' : 'not-needed',
  verificationAgree: 'not-needed',
};

export type Condition = {
  initialState: boolean;
  state: boolean;
  onClick: () => void;
  onSubmit?: () => Promise<void>;
  isLoading: boolean;
};

export const useMarketingStates = (
  option: MarketingAgreementOption = DEFAULT_OPTION,
): (Condition & { option: MarketingAgreementType; text: string })[] => {
  const notificationAgree = useNotificationAgree();
  const marketingAgree = useMarketingAgree();
  const verificationAgree = useVerificationAgree();
  const kakaoChannelAgree = useKakaoChannelAgree();

  return [
    ...(option.notificationAgree !== 'not-needed' && !notificationAgree.initialState
      ? [{ ...notificationAgree, option: option.notificationAgree, text: '알림 권한 허용' }]
      : []),
    ...(option.marketingAgree !== 'not-needed' && !marketingAgree.initialState
      ? [{ ...marketingAgree, option: option.marketingAgree, text: '마케팅 수신 동의' }]
      : []),
    ...(option.verificationAgree !== 'not-needed' && !verificationAgree.initialState
      ? [{ ...verificationAgree, option: option.verificationAgree, text: '본인 인증' }]
      : []),
    ...(option.kakaoChannelAgree !== 'not-needed' && !kakaoChannelAgree.initialState
      ? [{ ...kakaoChannelAgree, option: option.kakaoChannelAgree, text: '카카오 채널 친구 추가' }]
      : []),
  ];
};

const MarketingAgreementUI = ({
  marketingStates,
  isLoading,
  onSubmit,
  close,
  onReject,
}: {
  marketingStates: ReturnType<typeof useMarketingStates>;
  isLoading: boolean;
  onSubmit: () => void;
  close: () => void;
  onReject?: () => void;
}) => (
  <NewBottomSheetPortal
    container={typeof document !== 'undefined' ? document.getElementById('portal') : undefined}
  >
    {/* 
      BottomSheet가 Nested하게 사용됐을 떄, 한개의 BottomSheet만 작동하도록 함
      하나의 BottomSheet가 다른 BottomSheet의 Trigger가 되는 동작을 막음.
    */}
    <NewBottomSheetOverlay onClick={(e) => e.stopPropagation()} />
    <NewBottomSheetContentInnerContent onClick={(e) => e.stopPropagation()}>
      <div className="relative">
        <p className="text-new-Sub-Title flex flex-col gap-4 py-24">
          쿠폰을 받기 위해 동의가 필요해요.
        </p>
        <NewBottomSheetClose
          className="absolute right-0 top-[26.5px] cursor-pointer"
          onClick={close}
        >
          <NewIcon icon="xincircle-filled" size={24} className="fill-new-gray-400" />
        </NewBottomSheetClose>
      </div>
      <div className="flex flex-col gap-8">
        {marketingStates.map((s) => (
          <button className="flex items-center gap-8" key={s.option} onClick={s.onClick}>
            <NewIcon
              icon="check-outlined"
              size={24}
              className={cn(s.state ? 'fill-new-DTYellow-400' : 'fill-new-gray-400')}
            />
            <p className="text-new-Body2-medium">
              {s.option === 'optional' ? '(선택)' : ''}
              {s.text}
            </p>
          </button>
        ))}
      </div>
      <Spacer className="h-[24px]" />
      <Spacer className="h-[10px]" />
      <NewBoxButton
        fill
        label={'동의하고 쿠폰 받기'}
        onClick={async () => {
          if (isLoading) return;
          await Promise.all(marketingStates.map((state) => state.onSubmit?.()));
          onSubmit();
          close();
        }}
        disabled={marketingStates.some((state) => state.option === 'required' && !state.state)}
        asChild
      >
        {isLoading ? <Loading /> : <p>동의하고 쿠폰 받기</p>}
      </NewBoxButton>
      <Spacer className="h-[8px]" />
      <NewBottomSheetClose onClick={close}>
        <p onClick={onReject} className="text-new-Body2-medium text-new-gray-600 text-center">
          다음에
        </p>
      </NewBottomSheetClose>
    </NewBottomSheetContentInnerContent>
  </NewBottomSheetPortal>
);

export const MarketingAgreementBottomSheet = ({
  children,
  option = DEFAULT_OPTION,
  onSubmit,
  onReject,
  className,
  disabled = false,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  option?: MarketingAgreementOption;
  onSubmit: () => void;
  onReject?: () => void;
  className?: string;
  disabled?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const marketingStates = useMarketingStates(option);

  const canSkip =
    marketingStates.filter((state) => state.option === 'required').length === 0 ||
    marketingStates.every((state) => state.initialState);

  const isLoading = marketingStates.some((state) => state.isLoading);

  if (canSkip || disabled)
    return (
      <div
        className={cn('w-full', className)}
        {...props}
        onClick={(e) => {
          onSubmit();
          props.onClick?.(e);
        }}
      >
        {children}
      </div>
    );

  return (
    <NewBottomSheet open={isOpen} onOpenChange={setIsOpen} dismissible={false}>
      <NewBottomSheetTrigger asChild>{children}</NewBottomSheetTrigger>
      <MarketingAgreementUI
        marketingStates={marketingStates}
        isLoading={isLoading}
        onSubmit={() => {
          onSubmit();
          setIsOpen(false);
        }}
        close={() => setIsOpen(false)}
        onReject={onReject}
      />
    </NewBottomSheet>
  );
};

interface MarketingAgreementPageProps {
  children: React.ReactNode;
  onReject?: () => void;
  onSubmit: () => Promise<void> | void;
}

interface MarketingAgreementPageProps {
  children: React.ReactNode;
  onReject?: () => void;
  onSubmit: () => Promise<void> | void;
  // 추가된 props
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const MarketingAgreementPage: React.FC<MarketingAgreementPageProps> = ({
  children,
  onReject,
  onSubmit,
  open: externalOpen,
  onOpenChange,
}) => {
  const [internalShowConsent, setInternalShowConsent] = useState(false);
  const [consentAnimation, setConsentAnimation] = useState<'enter' | 'exit'>('enter');

  // 외부/내부 상태 통합
  const isControlled = externalOpen !== undefined;
  const showConsent = isControlled ? externalOpen : internalShowConsent;

  const setShowConsent = (value: boolean) => {
    if (!isControlled) {
      setInternalShowConsent(value);
    }
    onOpenChange?.(value);
  };

  // 마케팅 동의 상태 훅 (필요 옵션에 맞게 설정)
  const marketingStates = useMarketingStates({
    kakaoChannelAgree: 'optional',
    marketingAgree: 'required',
    verificationAgree: 'not-needed',
    notificationAgree: 'required',
  });

  // 트리거 클릭 시 모달 열기
  const openConsent = () => {
    setConsentAnimation('enter');
    setShowConsent(true);
  };

  // 동의 버튼 클릭 시 처리
  const handleAgree = async () => {
    // 마케팅 상태 업데이트 (각 항목의 onSubmit이 있을 경우 실행)
    await Promise.all(marketingStates.map((state) => state.onSubmit?.()));
    // 부모로부터 전달받은 동의 콜백 실행
    await onSubmit();
    // fade-out 애니메이션 후 모달 닫기
    setConsentAnimation('exit');
    setShowConsent(false);
  };

  // 모달 닫기(거부) 시 처리
  const handleReject = () => {
    onReject?.();
    setConsentAnimation('exit');
    setShowConsent(false);
  };

  return (
    <>
      {/* children을 감싸서 클릭 시 모달을 오픈합니다. */}
      <div onClick={openConsent}>{children}</div>

      {showConsent && (
        <Portal isBackgroundBlack={false} onClickBackground={handleReject}>
          <div className={`modal flex flex-col px-16 ${consentAnimation}`}>
            <div className="absolute left-16 top-14">
              <NewIcon
                icon="x-outlined"
                size={24}
                className="fill-new-gray-900"
                onClick={handleReject}
              />
            </div>
            <Spacer className="h-[52px]" />
            <Spacer className="h-[24px]" />
            <p className="text-new-Section-Title">쿠폰을 받기 위해 동의가 필요해요.</p>
            <Spacer className="h-[8px]" />
            <p className="text-new-Body2-medium text-new-gray-500">
              쿠폰으로 할인 받고 예약해보세요!
            </p>
            <Spacer className="h-[24px]" />
            <div className="bg-new-gray-50 flex items-center gap-10 rounded-[16px] p-16">
              <Image
                src="/events/univ-private/coupon-icon.png"
                alt="쿠폰 아이콘"
                width={80}
                height={80}
                className="h-[40px] w-[40px]"
              />
              <div>
                <p className="text-new-Body1-bold text-new-gray-900">
                  쿠폰 기한이 마감되기 전에 알려드릴게요!
                </p>
                <Spacer className="h-[4px]" />
                <p className="text-new-Caption2-medium text-new-gray-600">
                  쿠폰이 사라지기 전에 사용해보세요.
                </p>
              </div>
            </div>
            <Spacer className="h-[24px]" />
            <div className="flex flex-col gap-12">
              {marketingStates.map((v) => (
                <div key={v.text} className="flex items-center gap-8" onClick={v.onClick}>
                  <NewIcon
                    icon="checkincircle-filled"
                    className={v.state ? 'fill-new-DTPurple-500' : 'fill-new-gray-400'}
                  />
                  <p className="text-new-Body2-medium text-new-gray-900">
                    {v.option === 'optional' && '(선택)'} {v.text}
                  </p>
                </div>
              ))}
            </div>
            <div
              className={cn(
                'absolute bottom-12 flex w-[calc(100%-32px)] max-w-[468px] flex-col items-center justify-center',
                isAppApproaching({ isIOSApproaching: true }) && 'bottom-32',
              )}
            >
              <NewBoxButton
                onClick={handleAgree}
                styles="filled-yellow"
                label="동의하고 쿠폰 받기"
                fill
                disabled={marketingStates
                  .filter((v) => v.option === 'required')
                  .some((v) => !v.state)}
              />
              <Spacer className="h-[10px]" />
              <p className="text-new-Body2-medium text-new-gray-600" onClick={handleReject}>
                닫기
              </p>
            </div>
          </div>
        </Portal>
      )}

      <style jsx>{`
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          max-width: 500px;
          height: 100vh;
          background-color: #ffffff;
          z-index: 1000;
        }
        .modal.enter {
          animation: fadeInUp 0.5s forwards;
        }
        .modal.exit {
          animation: fadeOutDown 0.5s forwards;
        }
        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(100%);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        @keyframes fadeOutDown {
          from {
            opacity: 1;
            transform: translateY(0);
          }
          to {
            opacity: 0;
            transform: translateY(100%);
          }
        }
      `}</style>
    </>
  );
};

/**
 * useOverlay를 사용하면 외부에서 state 변경이 있어도 반영되지 않음
 * 컴포넌트 내부에서 상태를 가지고 있어야 함.
 */
const OverlayedMarketingAgreementUI = ({
  option,
  ...props
}: Omit<ComponentProps<typeof MarketingAgreementUI>, 'marketingStates' | 'isLoading'> & {
  option: MarketingAgreementOption;
}) => {
  const marketingStates = useMarketingStates(option);
  const isLoading = marketingStates.some((state) => state.isLoading);

  return (
    <MarketingAgreementUI {...props} marketingStates={marketingStates} isLoading={isLoading} />
  );
};
export const useOpenMarketingAgreementBottomSheet = (
  option: MarketingAgreementOption = DEFAULT_OPTION,
) => {
  const overlay = useOverlay();
  const marketingStates = useMarketingStates(option);
  const isLoading = marketingStates.some((state) => state.isLoading);

  return async () => {
    await new Promise<void>((resolve) => {
      if (marketingStates.length === 0 || isLoading) {
        resolve();
        return;
      }

      overlay.open(({ isOpen, close }) => (
        <NewBottomSheet open={isOpen} onOpenChange={close} dismissible={false}>
          <OverlayedMarketingAgreementUI
            option={option}
            onSubmit={() => {
              resolve();
              close();
            }}
            close={close}
          />
        </NewBottomSheet>
      ));
    });
  };
};
